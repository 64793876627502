import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { getImageFluxUrlFor } from 'utils/imageFlux'
import { formatCanonicalPath } from 'utils/formatCanonicalPath'
import { config } from 'config'
import { publicHead as t } from 'public/locales/ja/components/elements/publicHead'

const URL = `https://${config.host}`

type PublicHeadProps = {
  title?: string
  description?: string
  keywords?: string
  ogTitle?: string
  ogType?: string
  ogImage?: string
  ogSiteName?: string
  ogDescription?: string
  ogLocale?: string
  fbAppId?: string
  twitterCard?: string
  twitterSite?: string
  hasNoindexTag?: boolean
}

export const PublicHead: React.FC<React.PropsWithChildren<PublicHeadProps>> = ({
  title,
  description,
  keywords,
  ogTitle,
  ogType,
  ogImage,
  ogSiteName,
  ogDescription,
  ogLocale,
  fbAppId,
  twitterCard,
  twitterSite,
  hasNoindexTag = false,
}) => {
  const router = useRouter()
  const canonicalURL = `${URL}${formatCanonicalPath(router.asPath)}`

  return (
    <Head>
      {title && <title>{title}</title>}
      <link rel="shortcut icon" href={'/favicon.ico'} />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5, shrink-to-fit=no" />
      <meta name="description" content={description ? description : t.description} key="description" />
      {keywords && <meta name="keywords" content={keywords} key="keywords" />}
      <link rel="canonical" href={canonicalURL} />
      <meta property="og:title" content={ogTitle ? ogTitle : t.ogTitle} key="og:title" />
      <meta property="og:type" content={ogType ? ogType : t.website} />
      <meta property="og:url" content={canonicalURL} />
      <meta
        property="og:image"
        content={ogImage ? `${getImageFluxUrlFor(ogImage)}` : `${getImageFluxUrlFor('/images/ogp.png')}`}
        key="og:image"
      />
      <meta property="og:site_name" content={ogSiteName ? ogSiteName : t.ogTitle} key="og:site_name" />
      <meta property="og:description" content={ogDescription ? ogDescription : t.description} key="og:description" />
      <meta property="og:locale" content={ogLocale ? ogLocale : t.locale} key="og:locale" />
      <meta property="fb:app_id" content={fbAppId ? fbAppId : t.fbAppId} key="fb:app_id" />
      <meta property="twitter:card" content={twitterCard ? twitterCard : t.twitter.card} key="twitter:card" />
      <meta property="twitter:site" content={twitterSite ? twitterSite : t.twitter.site} key="twitter:site" />
      {hasNoindexTag && <meta name="robot" content="noindex" />}
    </Head>
  )
}
