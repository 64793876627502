import React, { ReactElement } from 'react'
import { Box, Stack, styled, Typography, useTheme, Link } from '@mui/material'
import NextLink from 'next/link'
import { formatDateTimezone } from 'utils/formatDate'

interface Notification {
  id: string
  date?: string
  badge?: string
  badgeColor?: string
  title: string
  isCorp?: boolean
  content: string
}

export const NotificationLink: React.FC<React.PropsWithChildren<Notification>> = ({
  id,
  date,
  isCorp,
  badge = 'お知らせ',
  badgeColor,
  title,
}): ReactElement => {
  const theme = useTheme()

  return (
    <StyledContainer bgcolor={isCorp ? theme.white.base : theme.tertiary.lightness4} direction={'row'}>
      <StyledDate>{formatDateTimezone(date, 'YYYY.MM.DD')}</StyledDate>
      <StyledBadge bgcolor={badgeColor || theme.grey.base}>{badge}</StyledBadge>
      <NextLink href={`/notices/${id}`} passHref legacyBehavior>
        <StyledLink>{title}</StyledLink>
      </NextLink>
    </StyledContainer>
  )
}

const StyledContainer = styled(Stack)(
  ({ theme }) => `
    align-items: flex-start;
    padding: ${theme.spacing(3)} ${theme.spacing(5)};
    border-radius: ${theme.spacing(0.625)};
    ${theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
    };
  `
)

const StyledDate = styled(Typography)(
  ({ theme }) => `
    margin-right: ${theme.spacing(3)};
  `
)

const StyledBadge = styled(Box)(
  ({ theme }) => `
    text-align: center;
    padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
    width: ${theme.spacing(14)};
    height: ${theme.spacing(3.625)};
    border-radius: ${theme.spacing(0.625)};
    color: ${theme.palette.common.black};
    font-weight: 600;
    margin-right: ${theme.spacing(3)};
    font-size: ${theme.spacing(1.5)};
  `
)

const StyledLink = styled(Link)(
  ({ theme }) => `
    width: calc(100% - 215px);
    text-align: start;
    color: ${theme.tertiary.darkness3};
    text-decoration: none;
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      padding-top: ${theme.spacing(1)};
    };
    &:hover {
      text-decoration: underline;
      text-decoration-color: ${theme.tertiary.darkness3};
    },
  `
)
