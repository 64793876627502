import { Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react'
import { getClient } from 'lib/cms'
import { News } from 'types/news'

const MAX_LIST_SIZE = 10 as const

type FetchNewses = (page?: number, categoryListId?: string[]) => Promise<void>
type FetchNewsDetail = (contentId: string) => Promise<void>
type UseNewsResponse = {
  newses: News[]
  setNewses: Dispatch<SetStateAction<News[]>>
  fetchNewses: FetchNewses
  totalPage: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
}

export function useNewses(isDmNotice?: boolean, initNewses?: News[]): UseNewsResponse {
  const [newses, setNewses] = useState<News[]>(initNewses || [])
  const [totalPage, setTotalPage] = useState<number>(0)
  const [page, setPage] = useState<number>(1)

  const fetchNewses = useCallback(
    async (page?: number, categoryListId?: string[]) => {
      const client = getClient()
      const { items, total } = await client.fetchNewses(page, isDmNotice, categoryListId)
      setNewses(items)
      setTotalPage(Math.ceil(total / MAX_LIST_SIZE))
      setPage(page || 1)
    },
    [isDmNotice]
  )

  useEffect(() => {
    if (isDmNotice) return
    fetchNewses(page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isDmNotice])

  return {
    newses,
    setNewses,
    fetchNewses,
    totalPage,
    page,
    setPage,
  }
}

type UseDetailNewsResponse = {
  fetchNewsDetail: FetchNewsDetail
  news: News | undefined
}

export function useDetailNews(id: string, isDmNotice?: boolean): UseDetailNewsResponse {
  const [news, setNews] = useState<News>()
  const fetchNewsDetail = useCallback(
    async (id: string) => {
      const client = getClient()
      const item = await client.fetchNews(id, isDmNotice)
      setNews(item)
    },
    [isDmNotice]
  )

  useEffect(() => {
    fetchNewsDetail(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {
    news,
    fetchNewsDetail,
  }
}
