// NOTE: Projects keep the URL for preset images.
// When you replace the image file, it will affect existing projects.
// If you want to modify the preset options without affecting existing projects, make sure to keep the old image file and modify `presets` array of the industry.
export const INDUSTRY_SETTING: { id: number; presets: string[]; icon: string }[] = [
  {
    // 建設・土木・工事業
    id: 1,
    presets: ['/images/industries/1/presets/helmet.png', '/images/industries/1/presets/building.png'],
    icon: '/images/industries/1/icon_helmet.png',
  },
  {
    // 製造・加工業
    id: 2,
    presets: ['/images/industries/2/presets/machine.png', '/images/industries/2/presets/warehouse.png'],
    icon: '/images/industries/2/icon_plant.png',
  },
  {
    // IT・ソフトウェア
    id: 3,
    presets: ['/images/industries/3/presets/it-laptop.png'],
    icon: '/images/industries/3/icon_desktop.png',
  },
  {
    // 卸売業・小売業
    id: 4,
    presets: ['/images/industries/4/presets/display.png'],
    icon: '/images/industries/4/icon_bag.png',
  },
  {
    // 住宅・不動産・リース業
    id: 5,
    presets: ['/images/industries/5/presets/houses.png'],
    icon: '/images/industries/5/icon_house.png',
  },
  {
    // 運送・物流・倉庫業
    id: 6,
    presets: ['/images/industries/6/presets/trucks.png', '/images/industries/6/presets/storages.png'],
    icon: '/images/industries/6/icon_truck.png',
  },
  {
    // 医療・介護・福祉関連
    id: 7,
    presets: ['/images/industries/7/presets/checkup.png'],
    icon: '/images/industries/7/icon_medic.png',
  },
  {
    // 各種サービス業
    id: 8,
    presets: ['/images/industries/8/presets/service.png'],
    icon: '/images/industries/8/icon_ticket.png',
  },
  {
    // 飲食業
    id: 9,
    presets: ['/images/industries/9/presets/restaurant.png'],
    icon: '/images/industries/9/icon_dining.png',
  },
  {
    // その他
    id: 10,
    presets: ['/images/industries/10/presets/shakehands.png'],
    icon: '/images/industries/10/icon_else.png',
  },
  {
    // エステ・美容・マッサージ
    id: 11,
    presets: ['/images/industries/11/presets/massage.png'],
    icon: '/images/industries/11/icon_mask.png',
  },
  {
    // ホテル・宿泊
    id: 12,
    presets: ['/images/industries/12/presets/hotel.png'],
    icon: '/images/industries/12/icon_bed.png',
  },
  {
    // 人材
    id: 13,
    presets: ['/images/industries/13/presets/shakehands.png'],
    icon: '/images/industries/13/icon_recruit.png',
  },
  {
    // アパレル"
    id: 14,
    presets: ['/images/industries/14/presets/clothes.png'],
    icon: '/images/industries/14/icon_shirt.png',
  },
  {
    // 教育・語学
    id: 15,
    presets: ['/images/industries/15/presets/classroom.png'],
    icon: '/images/industries/15/icon_listening.png',
  },
]
