export const financialInstitutionServices = {
  supportingNationWide: 'M&A支援業務をDXする',
  financialServices: 'M&A支援機関向けサービス ',
  description:
    '「M&Aナビ クラウド」はM&A支援機関様が事業承継ニーズやM&Aニーズを管理するためのクラウドサービスです。\nM&A支援機関様のこれまでの業務をDXすることで生産性を向上させます。\n弊社はM&A支援機関様が、これまで以上に幅広くの中小企業様を支援できるように、お手伝いしています。',
  affiliatedFinancial: 'サービス利用M&A支援機関',
  inquiriesFinancial: 'M&A支援機関の方のお問い合わせはこちら',
  manaviFinancialIntroduction: 'M&Aナビ導入\nM&A支援機関向けサービス',
  noteLogo:
    '※ ご契約いただいたM&A支援機関様の中でロゴ掲載の許諾を得たもののみを掲示しています\n※ ロゴはサービス導入をいただいた順に掲示しています',
}
