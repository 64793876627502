import React, { ReactElement, VFC } from 'react'
import { Typography, Box, useTheme, styled, useMediaQuery, Stack } from '@mui/material'
import { ImageFlux } from 'components/elements/ImageFlux'
import { usageFee as t } from 'public/locales/ja/components/elements/usageFee'

export const UsageFee: VFC = (): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box>
      <Title variant={'h2'} align={'center'}>
        {t.title}
      </Title>
      <Typography
        mt={6.25}
        mb={[5, 5.8]}
        fontWeight={500}
        fontSize={theme.typography.pxToRem(16)}
        lineHeight={'175%'}
        dangerouslySetInnerHTML={{ __html: t.content }}
      />
      <Typography fontWeight={500} fontSize={theme.typography.pxToRem(16)} lineHeight={'175%'} mb={[2.5, 0]}>
        {t.feeSystem}
      </Typography>
      {isMobile ? (
        <Stack alignItems={'center'}>
          <Box maxWidth={'339px'}>
            <ImageFlux src={'/images/price_sp.png'} width={'339'} height={'636'} alt={'usage_fee_sp'} />
          </Box>
        </Stack>
      ) : (
        <ImageFlux src={'/images/usage_fee_pc.png'} width={'1014'} height={'352'} alt={'usage_fee'} />
      )}
    </Box>
  )
}
const Title = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(40)};
  ${theme.breakpoints.down('sm')} {
    font-size: ${theme.typography.pxToRem(24)};
  }
`
)
