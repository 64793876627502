import React from 'react'
import { Box, Stack, Typography, styled } from '@mui/material'
import { ImageFlux } from 'components/elements/ImageFlux'

import { mediaSection } from 'public/locales/ja/components/elements/mediaSection'

export const MediaSection: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <MediaSectionWrapper>
      <TitleWrapper>
        <Typography variant={'h2'}>{mediaSection.title}</Typography>
      </TitleWrapper>
      <MediaSectionImagesWrapper direction="row" justifyContent="space-around" alignItems="center">
        <ImageContainer maxWidth={204}>
          <ImageFlux src={'/images/matching/media_xtech.png'} loading={'lazy'} width={'204'} alt="media_xtech" />
        </ImageContainer>
        <ImageContainer maxWidth={280}>
          <ImageFlux
            src={'/images/matching/media_fuji_television.png'}
            loading={'lazy'}
            width={'280'}
            alt="media_fuji_television"
          />
        </ImageContainer>
        <ImageContainer maxWidth={224}>
          <ImageFlux
            src={'/images/matching/media_gold_online.png'}
            loading={'lazy'}
            width={'224'}
            alt="media_gold_online"
          />
        </ImageContainer>
        <ImageContainer maxWidth={318}>
          <ImageFlux
            src={'/images/matching/media_jp_bank_communication.png'}
            loading={'lazy'}
            width={'318'}
            alt="media_jp_bank_communication"
          />
        </ImageContainer>
        <ImageContainer maxWidth={284}>
          <ImageFlux src={'/images/matching/media_weekly.png'} loading={'lazy'} width={'284'} alt="media_weekly" />
        </ImageContainer>
      </MediaSectionImagesWrapper>
    </MediaSectionWrapper>
  )
}

const ImageContainer = styled(Box)(
  () => `
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`
)

const TitleWrapper = styled(Box)(
  ({ theme }) => `
  padding-bottom:  ${theme.spacing(8)};
  ${theme.breakpoints.down('sm')} {
    padding-bottom:  ${theme.spacing(5)};
  }
  text-align: center;
`
)

const MediaSectionWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(8)} ${theme.spacing(12.5)};
    ${theme.breakpoints.down('sm')} {
    padding:  ${theme.spacing(8)} ${theme.spacing(3)};
  }
  `
)

const MediaSectionImagesWrapper = styled(Stack)(
  ({ theme }) => `
  flex-wrap: wrap;
  gap: ${theme.spacing(3)};
  ${theme.breakpoints.down('sm')} {
    flex-direction: 'column';
    gap: ${theme.spacing(2)};
  }`
)
