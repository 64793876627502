import React, { useCallback, useState } from 'react'
import { Typography, Stack, Collapse, IconButton, Divider, useTheme, styled } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { ImageFlux } from 'components/elements/ImageFlux'

export const enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

type Question = {
  title: string
  answer: string
}

type FAQListProps = {
  title: string
  image: string
  altImg: string
  list: Question[]
  position?: Position.LEFT | Position.RIGHT
}

export const FAQList: React.FC<React.PropsWithChildren<FAQListProps>> = ({
  title,
  image,
  altImg,
  list,
  position = Position.LEFT,
}) => {
  return (
    <Container>
      <HeaderQuestionList direction={'row'} alignItems={'center'} justifyContent={'center'}>
        {position === Position.LEFT && (
          <ImageFAQ src={image} loading={'lazy'} width={'62'} height={'62'} alt={altImg} />
        )}
        <Typography sx={{ paddingLeft: 1.5, paddingRight: 1.5 }} fontWeight={'bold'} color={'primary'}>
          {title}
        </Typography>
        {position === Position.RIGHT && (
          <ImageFAQ src={image} loading={'lazy'} width={'62'} height={'62'} alt={altImg} />
        )}
      </HeaderQuestionList>
      <Stack overflow={'hidden'} alignItems={'center'}>
        <ImageFlux src={'/images/matching/faq/divider.png'} alt="divider" height={'14'} width={'700'} />
      </Stack>
      <Stack divider={<Divider light={true} />}>
        {list.map((item, index) => (
          <QuestionItem key={index} answer={item.answer} title={item.title} />
        ))}
      </Stack>
    </Container>
  )
}

const QuestionItem: React.FC<React.PropsWithChildren<Question>> = ({ title, answer }) => {
  const theme = useTheme()
  const [toggle, setToggle] = useState(false)

  const handleToggleQuestion = useCallback(() => {
    setToggle(!toggle)
  }, [toggle])

  return (
    <div>
      <QuestionItemStyled direction={'row'} alignItems={'center'} spacing={2} onClick={handleToggleQuestion}>
        <Typography color={'primary'} sx={{ fontWeight: 500, fontSize: 24 }}>
          Q
        </Typography>
        <Typography sx={{ flex: 1, fontSize: 14, fontWeight: 700 }}>{title}</Typography>
        <IconButton
          aria-label="add"
          onClick={handleToggleQuestion}
          sx={{ background: toggle ? theme.tertiary.lightness4 : 'none', width: 24, height: 24, borderRadius: 0 }}
        >
          <AddIcon />
        </IconButton>
      </QuestionItemStyled>
      <Collapse in={toggle}>
        <CollapseAnswerWrapper direction={'row'} spacing={2}>
          <Typography sx={{ fontWeight: 500, color: theme.tertiary.lightness2, fontSize: 24 }}>A</Typography>
          <Typography variant="body2" sx={{ flex: 1, fontWeight: 400, fontSize: 14, lineHeight: '175%' }}>
            {answer}
          </Typography>
        </CollapseAnswerWrapper>
      </Collapse>
    </div>
  )
}

const Container = styled('div')(
  ({ theme }) => `
  border-radius: 6px;
  background: ${theme.palette.common.white};
`
)

const HeaderQuestionList = styled(Stack)(
  ({ theme }) => `
  height: 92px;
  border-radius: 6px;
  background: ${theme.palette.common.white};
`
)

const ImageFAQ = styled('img')(
  () => `
  width: 62px;
  height: auto;
`
)

const QuestionItemStyled = styled(Stack)(
  ({ theme }) => `
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  cursor: pointer;
`
)

const CollapseAnswerWrapper = styled(Stack)(
  ({ theme }) => `
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  border-top: 1px dashed ${theme.grey.lightness2};
  border-bottom: 1px solid ${theme.grey.lightness2};
`
)
