import { User, UserOnlyName, UserRole } from './user'
import { Prefecture } from './prefecture'
import { OfferWithProject } from './offer'
import { Project, ProjectSaleReason } from './project'

export enum CountryCode {
  Japan = 0,
  USA = 1,
  Singapore = 2,
  Phillipines = 3,
  Cambodia = 4,
  China = 5,
  HonKong = 6,
  Other = 999,
}

export enum CorporateClassification {
  Corporate = 1,
  Limited = 2,
  Medical = 3,
  OtherCorporate = 5,
  Indivisual = 6,
}

export enum EmployeeNum {
  NoEmployee = 0,
  FiveDown = 1,
  TenDown = 6,
  TwentyDown = 11,
  ThirtyDown = 21,
  FiftyDown = 31,
  OneHundredDown = 51,
  TwoHundredDown = 101,
  TwoHundredOneUp = 201,
}

export interface Company {
  id: number
  name: string
  corprateClassification: CorporateClassification
  url: string
  representativePositionName: string
  representativeName: string
  representativeTel: string
  countryCode: CountryCode
  zipcode: string
  prefectureId: number | null
  prefectures: Prefecture
  city: string | null
  state: string | null
  address: string
  building: string
  createdAt: Date
  updatedAt: Date
}

export interface GetAdminCompany extends Company {
  user?: User & { projects: (Project & { projectsSaleReasons: ProjectSaleReason[]; company: Company | undefined })[] }
  createdUser?: UserOnlyName
  createdAdmin?: { name: string | null } | null
  updatedUser?: UserOnlyName
  updatedAdmin?: { name: string | null } | null
  offers?: OfferWithProject[]
}

export interface AdminCompany extends Company {
  users: {
    id: number
    role: UserRole
    firstName: string
    lastName: string
    coordinators: []
  }[]
}

export interface AdressHooksParams {
  name?: string
  countryCode?: CountryCode
  zipcode?: string
  prefectureId?: number | null
  city?: string | null
  state?: string | null
  address?: string
  building?: string
}

export type CompanyWithRegion = Company & {
  prefectures?: {
    id: number
    regionId: number
    name: string
    regions?: {
      id: number
      name: string
    }
  }
}

export type AboutUsForm = {
  businessName?: string
  representativePosition?: string
  representativeName?: string
  phoneNumber?: string
  companyURL?: string
  targetForSale?: string
}
