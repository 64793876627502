export const modalRequestAction = {
  registrationInfo: {
    title: 'お気に入り・交渉リクエストをするには会員登録が必要です',
    buttonTitle: '会員登録へ進む',
    otherLinkTitle: '会員登録済みの方はこちら',
  },
  buyerFavoriteAndOffer: {
    titleFavorite: 'お気に入りに登録するには買い手としての登録が必要です',
    titleOffer: '交渉リクエストをするには買い手としての登録が必要です',
    contentTitle: '買い手',
    description: {
      text1: '案件の財務情報の閲覧',
      text2: '売り手とのM&A交渉',
      text3: '新着案件の受信',
    },
    buttonTitle: '登録する',
    bottomText: '※売り手として登録したメールアドレスとは別のメールアドレスでの登録が必要です',
  },
  identification: {
    title: '交渉リクエストを送るには本人確認が必要です。',
    buttonTitle: '本人確認にすすむ',
  },
  noticeIdentification: {
    title: 'あなたの本人確認が承認されました！',
    description: {
      text1: '気になる案件を見つけて、お気に入りに追加してみましょう！',
      text2: 'これは！と思う案件に交渉リクエストを送ってみましょう！',
    },
    buttonTitle: 'ホームを確認する',
  },
  userCoordinator: {
    title: '専門家の紹介依頼をするには会員登録が必要です',
  },
}
