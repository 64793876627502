import React, { MouseEventHandler } from 'react'
import {
  Backdrop,
  Fade,
  Box,
  Modal,
  Paper,
  IconButton,
  Typography,
  Button,
  Stack,
  styled,
  ListItem,
  ListItemText,
  List,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Link from 'next/link'
import { getFileNameFromPath } from 'utils/file'
import { ImageFlux } from 'components/elements/ImageFlux'

export type Description = {
  imageUrl?: string
  text?: string
  isEmphasized?: boolean
}

type ModalRequestActionProps = {
  isOpen: boolean
  isHiddenClose?: boolean
  onClose?: MouseEventHandler
  title: string
  imageUrl: string
  contentTitle?: string
  descriptions?: Description[]
  buttonTitle: string
  buttonUrl: string
  otherLinkTitle?: string
  otherLinkUrl?: string
  bottomText?: string
}

export const ModalRequestAction: React.FC<React.PropsWithChildren<ModalRequestActionProps>> = ({
  isOpen,
  isHiddenClose,
  onClose,
  title,
  imageUrl,
  contentTitle,
  descriptions,
  buttonTitle,
  buttonUrl,
  otherLinkTitle,
  otherLinkUrl,
  bottomText,
}) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={isOpen}>
        <ModalWrapper>
          <StyledModalHeader justifyContent={'center'}>
            <StyledTypography variant="h3">{title}</StyledTypography>
            {!isHiddenClose && (
              <StyledIconButton onClick={onClose}>
                <CloseIcon />
              </StyledIconButton>
            )}
          </StyledModalHeader>
          <StyledContent>
            <ImageWrapper>
              <ImageFlux src={imageUrl} width={'342'} alt={getFileNameFromPath(imageUrl)} />
            </ImageWrapper>
            {contentTitle && (
              <Typography variant={'h2'} py={{ xs: 1, sm: 2 }}>
                {contentTitle}
              </Typography>
            )}
            <List disablePadding>
              {descriptions?.map((item, index) => (
                <ListItem key={index}>
                  {item.imageUrl && (
                    <ImageDescription>
                      <ImageFlux src={item.imageUrl} width={'24'} alt={getFileNameFromPath(item.imageUrl)} />
                    </ImageDescription>
                  )}
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'body1',
                      fontWeight: item.isEmphasized ? 700 : 500,
                      fontSize: item.isEmphasized ? '1.1rem' : 'inherit',
                    }}
                    sx={{ whiteSpace: 'pre-wrap' }}
                  >
                    {item.text}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Link href={buttonUrl} passHref legacyBehavior>
              <StyledButton variant={'contained'} onClick={onClose}>
                <ButtonTypo>{buttonTitle}</ButtonTypo>
              </StyledButton>
            </Link>
            {otherLinkUrl && (
              <Link href={otherLinkUrl} passHref legacyBehavior>
                <Anchor>
                  <ButtonTypo onClick={onClose}>{otherLinkTitle}</ButtonTypo>
                </Anchor>
              </Link>
            )}
            {bottomText && (
              <Typography textAlign="center" variant="body2" mt={2}>
                {bottomText}
              </Typography>
            )}
          </StyledContent>
        </ModalWrapper>
      </Fade>
    </StyledModal>
  )
}
const StyledModal = styled(Modal)(
  () => `
    display: flex;
    justify-content: center;
    align-items: center;
  `
)
const ModalWrapper = styled(Paper)(
  ({ theme }) => `
    position: relative;
    max-width: 984px;
    width: 75%;
    overflow: auto;
    max-height: 90%;
    padding: 0;
    ${theme.breakpoints.down('md')} {
      width: 90%;
    }
  `
)

const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(2)};
  ${theme.breakpoints.down('md')} {
    right: ${theme.spacing(1)};
  };
`
)

const StyledModalHeader = styled(Stack)(
  ({ theme }) => `
    background: ${theme.blue.base};
    padding: ${theme.spacing(3)};
  `
)

const StyledButton = styled(Button)(
  ({ theme }) => `
    margin-top: ${theme.spacing(3)};
    color: ${theme.palette.common.white};
    padding: ${theme.spacing(1.5)} ${theme.spacing(13.8)};
    text-align: center;
    ${theme.breakpoints.down('md')} {
      margin-top: ${theme.spacing(2)};
      padding: ${theme.spacing(1.5)} ${theme.spacing(7)};
    }
  `
)

const StyledTypography = styled(Typography)(
  ({ theme }) => `
    text-align: center;
    padding: 0 ${theme.spacing(4)};
    ${theme.breakpoints.down('md')} {
      font-size: ${theme.typography.pxToRem(16)};
    }
  `
)

const ImageWrapper = styled(Box)(
  () => `
    max-width: 342px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `
)

const ImageDescription = styled(Box)(
  ({ theme }) => `
    max-width: 24px;
    display: inline-flex;
    margin-right: ${theme.spacing(1)};
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `
)

const Anchor = styled('a')(
  ({ theme }) => `
    color: ${theme.primary.base};
    text-decoration: underline;
    margin-top: ${theme.spacing(3)};
    ${theme.breakpoints.down('sm')} {
      margin-top: ${theme.spacing(2)};
    }

  `
)

const ButtonTypo = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(14)};
    font-weight: bold;
  `
)

const StyledContent = styled(Stack)(
  ({ theme }) => `
    padding: ${theme.spacing(4)} ${theme.spacing(5)};
    align-items: center;
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(3)};
    }
  `
)
