import React, { ReactElement } from 'react'
import { GetStaticProps } from 'next'
import axios from 'axios'
import get from 'lodash/get'
import { Tag } from 'types/tag'

import { useAuth } from 'context/authContext'
import { useFavorites } from 'hooks/useFavorites'
import { fetchFromApi } from 'lib/fetchFromApi'

import { ProjectMatching } from 'types/project'
import { Industry } from 'types/industry'

import { Home } from 'components/pages/home'
import { MatchingLayout } from 'components/modules/MatchingLayout'
import { homePage as t } from 'public/locales/ja/pages'
import { Article } from 'components/elements/ListColumnArticle'
import { PublicHead } from 'components/elements/PublicHead'
import { Region } from 'types/region'
import { Prefecture } from 'types/prefecture'

type ArticleResponse = {
  id: number
  slug: string
  title: {
    rendered: string
  }
  link: string
  date: string
  modified: string
  tags: number[]
}

type HomePageProps = {
  newProjects: ProjectMatching[]
  industries: Industry[]
  regions: Region[]
  articles: Article[]
}

const PAGE = 1
const PROJECT_SIZE = 6

const HomePage = ({ newProjects, articles, industries }: HomePageProps): ReactElement => {
  const { user } = useAuth()
  const { userFavoriteIds, handleClickFavorite } = useFavorites(user)

  return (
    <>
      <PublicHead
        title={t.title}
        description={t.description}
        ogTitle={t.title}
        ogDescription={t.description}
        keywords={t.keywords}
      />
      <Home
        user={user}
        articleList={articles}
        newProjects={newProjects}
        industries={industries}
        userFavoriteIds={userFavoriteIds}
        onClickFavorite={handleClickFavorite}
      />
    </>
  )
}

HomePage.getLayout = function PageInLayout(page: ReactElement): ReactElement {
  return (
    <MatchingLayout
      industries={page.props.industries}
      regions={page.props.regions}
      prefectures={page.props.prefectures}
    >
      {page}
    </MatchingLayout>
  )
}

export default HomePage

export const getStaticProps: GetStaticProps = async () => {
  const paramsProjects = {
    page: PAGE,
    size: PROJECT_SIZE,
  }

  const [newProjects, industries, regions, prefectures, listArticleRes] = await Promise.all([
    fetchFromApi<{ projects: ProjectMatching[] }>('/api/projects/new-projects', { params: paramsProjects }).then(
      (data) => data.projects
    ),
    fetchFromApi<Industry[]>('/api/industries'),
    fetchFromApi<Region[]>('/api/regions'),
    fetchFromApi<Prefecture[]>('/api/prefectures'),
    axios
      .get<ArticleResponse[]>('https://ma-navigator.com/columns/wp-json/wp/v2/posts?_embed&per_page=3&orderby=modified')
      .then((res) => res.data),
  ])

  const listArticleTags: Promise<Tag>[] = listArticleRes.map((article) =>
    axios.get(`https://ma-navigator.com/columns/wp-json/wp/v2/tags?post=${article.id}`).then((res) => res.data)
  )
  const articleTagsRes = await Promise.all(listArticleTags)

  const articles: Article[] =
    listArticleRes.map((article, index) => {
      const tags = articleTagsRes[index] as unknown as Tag[]
      return {
        id: article.id,
        slug: article.slug,
        title: article.title.rendered,
        date: article.modified,
        link: article.link,
        tags: tags,
        imgUrl: get(article, '_embedded.wp:featuredmedia[0].source_url', ''),
      }
    }) || []

  return {
    props: {
      linksConnect: true,
      newProjects,
      industries,
      regions,
      prefectures,
      articles: articles || [],
    },
    revalidate: 10,
  }
}
