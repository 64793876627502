import { matchingFaq } from 'public/locales/ja/pages/matching/faq'
import { modalRequestAction } from 'public/locales/ja/components/elements/modalRequestAction'

export const home = {
  modalRequestAction,
  matchingFaq,
  title: 'M&Aナビ - 中小企業に特化した事業承継・M&Aプラットフォーム',
  heading: 'M&A・事業承継ならM&Aナビ',
  description: '会員登録（無料）、成約時に費用が発生',
  note: '3月15日 M&Aナビの無料化に関する',
  noteSub: 'プレスリリースはこちら',
  complete: {
    heading: '2021年3月15日より\nM&Aナビを完全無料化',
    description: '売り手はもちろん、買い手・専門家も完全無料になりました。',
    note: '買い手の手数料の例',
  },
  label: {
    feature: '特集',
    newDeal: '新着の売却案件',
    viewDeal: '最近閲覧した売却案件',
    recommend: '閲覧した履歴にもとづくおすすめ',
    industry: '業種から探す',
    area: '地域から探す',
    sale: '売上高から探す',
    price: '買収金額から探す',
  },
  saleIndustry: '関連業の売却案件',
  button: {
    seeMore: 'もっとみる',
    signUp: '会員登録（無料）',
  },
  heroBanner: {
    title: '事業の縁をつなぐ。',
    subTitle: '最良の選択が見つかるM&Aプラットフォーム',
  },
  matchingContrivance: {
    nextButton: '次の一歩を最大化させる',
    title: 'M&Aナビのしくみ',
    description:
      'M&Aナビは、売り手も買い手も無料で利用できるオンラインM&Aサービスです（買い手のみ成約時に費用が発生）。\n情報掲載から成約完了まで、オンラインならではの手軽さでスピーディーに利用できます。',
  },
  industriesSearch: {
    title: '業種から探す',
    setDetails: '細かい検索条件を設定',
  },
  newProjectsDeal: {
    title: '新着の案件',
  },
}
