import { common } from '../../common'
import { validation } from '../../validation'

export const currency = {
  common,
  validation,
  thousand: '千円',
  tenThousand: '万円',
  oneMillion: '百万円',
  oneHundredMillion: '億円',
}
