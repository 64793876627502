import React from 'react'
import { Box, styled, Stack } from '@mui/material'
import { MultiLineTypography } from 'components/elements/MultiLineTypography'
import { ImageFlux } from 'components/elements/ImageFlux'

type ImageWrapperProps = {
  imgPosition: 'top' | 'center'
}

type MatchingContrivanceProps = {
  imgUrl: string
  altImg: string
  title: string
  description: string
  imgPosition: 'top' | 'center'
  width?: string
  height?: string
}

export const MatchingContrivance: React.FC<React.PropsWithChildren<MatchingContrivanceProps>> = ({
  imgUrl,
  altImg,
  title,
  description,
  imgPosition,
  width,
  height,
}) => {
  return (
    <Wrapper>
      <HeaderWrapper alignItems={'center'} justifyContent={'center'}>
        <ImageWrapper imgPosition={imgPosition}>
          <ImageFlux src={imgUrl} loading={'lazy'} width={`${width ?? '240'}`} height={`${height}`} alt={altImg} />
        </ImageWrapper>
      </HeaderWrapper>
      <Stack rowGap={5} pt={8.5}>
        <MultiLineTypography variant={'h3'} fontSize={20}>
          {title}
        </MultiLineTypography>
        <MultiLineTypography variant={'body1'}>{description}</MultiLineTypography>
      </Stack>
    </Wrapper>
  )
}

const Wrapper = styled(Box)(
  ({ theme }) => `
  position: relative;
  max-width: 334px;
  min-height: 294px;
  width: 100%;
  background-color: ${theme.blue.base};
  margin-top: ${theme.spacing(12)};
  text-align: center;
  & .MuiTypography-root {
    z-index: 2;
  }
`
)

const ImageWrapper = styled(Box)<ImageWrapperProps>(
  ({ theme, imgPosition }) => `
  position: absolute;
  top: ${imgPosition === 'top' ? `${theme.spacing(-2.25)}` : 'unset'};
  bottom: ${imgPosition === 'top' ? 0 : 'unset'};
`
)

const HeaderWrapper = styled(Stack)(
  ({ theme }) => `
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: ${theme.spacing(-12)};
  z-index: 1;
  &:after {
    content: "";
    width: 191px;
    height: 191px;
    border-radius: 50%;
    background: ${theme.blue.base};
  }
`
)
