import React, { useState, useEffect } from 'react'
import ImageIcon from '@mui/icons-material/Image'
import { styled, Stack } from '@mui/material'
import omit from 'lodash/omit'
import Image from 'next/image'
import { ImageFlux } from 'components/elements/ImageFlux'
import { ASPECT_MODE } from 'utils/imageFlux'

type WidthOrHeightRequired = { width: string; height?: string } | { width?: string; height: string }
type ImageProps = {
  url: string
  alt: string
  loading?: 'eager' | 'lazy'
  isPrivate?: boolean
  noImageFontSize?: number
  aspectMode?: keyof typeof ASPECT_MODE
  irAuto?: boolean
} & WidthOrHeightRequired

export const ImageFallback: React.FC<React.PropsWithChildren<ImageProps>> = (props) => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(false)
  }, [props.url])

  const handleError = () => {
    setHasError(true)
  }

  if (hasError) {
    return (
      <NoImageStyled style={{ height: props.height + 'px' }} fontSize={props.noImageFontSize}>
        <ImageIcon fontSize={'inherit'} />
      </NoImageStyled>
    )
  }

  const options: ImageProps & { onError: () => void; src: string } = {
    ...(omit(props, 'isPrivate') as ImageProps),
    onError: handleError,
    src: props.url,
  }
  if (props.isPrivate) {
    if (props.url.indexOf('/api') === 0) {
      // eslint-disable-next-line @next/next/no-img-element
      return <img {...options} alt={props.alt} />
    }
    const nextImageOptions = {
      ...options,
      height: options.height ? Number(options.height) : undefined,
      width: options.width ? Number(options.width) : undefined,
    }
    return <Image {...nextImageOptions} alt={props.alt} />
  }

  return <ImageFlux {...options} />
}

const NoImageStyled = styled(Stack)<{ fontSize?: number }>(
  ({ theme, fontSize = 40 }) => `
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${theme.grey.darkness2};
    font-size: ${theme.typography.pxToRem(fontSize)}
  `
)
