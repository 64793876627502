import React, { FC } from 'react'
import { Button as MuiButton } from '@mui/material'
import type { ButtonProps as MuiButtonProps } from '@mui/material'

type ButtonProps = {
  type?: 'submit' | 'reset' | 'button'
  color?: 'inherit' | 'primary' | 'secondary' | 'tertiary' | 'success' | 'error' | 'info' | 'warning' | 'danger'
  onClick?: () => void
} & Pick<MuiButtonProps, 'variant' | 'disabled' | 'sx' | 'size' | 'startIcon' | 'disableRipple'>

export const Button: FC<React.PropsWithChildren<ButtonProps>> = ({
  type = 'submit',
  color = 'primary',
  children,
  variant = 'contained',
  disabled = false,
  onClick,
  ...others
}) => {
  return (
    <MuiButton type={type} color={color} variant={variant} disabled={disabled} onClick={onClick} {...others}>
      {children}
    </MuiButton>
  )
}
