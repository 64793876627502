import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { EMPTY_STRING } from 'utils/string'

moment.locale('ja')

export function formatYearMonth(date: string | Date): string {
  return moment(date).format('YYYY年MM月')
}

export function formatFinancialYearMonth(year?: number | null, month?: number | null): string {
  if (!month && !year) return EMPTY_STRING
  const yearFinancial = year ? `${year}年` : ''
  const monthFinancial = month ? `${month}月` : ''
  return `${yearFinancial}${monthFinancial}`
}

export function formatUsualDate(date: string | Date): string {
  return moment(date).format('YYYY/MM/DD H:mm')
}

export function formatMonthDay(date: string | Date): string {
  return moment(date).format('MM/DD H:mm')
}

export function formatDate(date?: string | Date | null, defaultValue?: string): string {
  if (!date) return defaultValue || ''
  return moment(date).format('YYYY年M月D日')
}

export function formatDateTime(time: string | Date | undefined | null): string {
  return momentTimezone(time).tz('Asia/Tokyo').format('YYYY年M月D日 H:mm')
}

export function formatDateTimezone(date: string | Date | null | undefined, format?: string): string {
  if (!date) return ''
  return momentTimezone(date)
    .tz('Asia/Tokyo')
    .format(format || 'YYYY/MM/DD HH:mm:ss')
}

export function isDateFormat(date: string): boolean {
  if (isNaN(Date.parse(date))) return false
  const dateRegex = /^(\d{4})\/(\d{1,2})\/(\d{1,2})?$/ // YYYY/MM/DD
  return dateRegex.test(date)
}

export function formatDateShort(date: string | Date): string {
  return moment(date).format('YYMMDD')
}

// alliance用
export function formatDateAl(date: string | Date): string {
  return moment(date).format('YYYY/MM/DD')
}

export function convertToJapanTimeAsDate(date: Date): string {
  // Momentオブジェクトを日本時間に変換
  return moment(date).tz('Asia/Tokyo').format('YYYY-MM-DDTHH:mm:ssZ')
}
