import React, { useCallback } from 'react'
import Link from 'next/link'
import { Box, styled, Typography, Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { financialInstitutionServices as t } from 'public/locales/ja/components/pages/home/financialInstitutionServices'
import { ImageFlux } from 'components/elements/ImageFlux'
import { MultiLineTypography } from 'components/elements/MultiLineTypography'

export const FinancialInstitutionServices: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const renderCooperator = useCallback((image: { url: string; altImg: string }) => {
    return (
      <CooperatorLogoContainer>
        <ImageFlux src={image.url} loading={'lazy'} width={'172'} alt={image.altImg} />
      </CooperatorLogoContainer>
    )
  }, [])

  return (
    <Wrapper>
      <Box pb={5}>
        <Typography sx={{ pb: 1.75 }} fontWeight={'bold'} fontSize={20} color={'primary'} align={'center'}>
          {t.supportingNationWide}
        </Typography>
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          alignItems={'center'}
          justifyContent={'center'}
          rowGap={2}
          columnGap={2.75}
        >
          <Title variant={'h2'} align={'center'}>
            {isMobile ? t.manaviFinancialIntroduction : t.financialServices}
          </Title>
          {!isMobile && (
            <LogoContainer>
              <ImageFlux
                src={'/images/matching/manavi_logo_2.png'}
                loading={'lazy'}
                width={'404'}
                alt="manavi_logo_2"
              />
            </LogoContainer>
          )}
        </Stack>
      </Box>
      <ContentWrapper direction={'row'}>
        <ImageContainer>
          <ImageFlux
            src={'/images/matching/financialInstitutionServices.jpg'}
            loading={'lazy'}
            width={'364'}
            height={'173'}
            alt="financialInstitutionServices"
          />
        </ImageContainer>
        <Description>
          <MultiLineTypography variant={'body1'}>{t.description}</MultiLineTypography>
        </Description>
      </ContentWrapper>
      <Typography
        variant={'body1'}
        color={theme.tertiary.lightness1}
        align={'center'}
        sx={{ pb: 1.5 }}
        fontWeight={700}
      >
        {t.affiliatedFinancial}
      </Typography>
      <CooperatorCompanies>
        <CooperatorCompaniesWrapper direction={'row'} alignItems={'center'} justifyContent={'center'} gap={2}>
          {renderCooperator({
            url: '/images/matching/cooperation1.png',
            altImg: 'matching_cooperation1',
          })}
          {renderCooperator({
            url: '/images/matching/cooperation2.png',
            altImg: 'matching_cooperation2',
          })}
          {renderCooperator({
            url: '/images/matching/cooperation3.png',
            altImg: 'matching_cooperation3',
          })}
          {renderCooperator({
            url: '/images/matching/cooperation4.png',
            altImg: 'matching_cooperation4',
          })}
          {renderCooperator({
            url: '/images/shindan-form/nishichugokuShinkin/logo.png',
            altImg: 'nishichugokuShinkin_logo',
          })}
          {renderCooperator({
            url: '/images/matching/shoko_logo1.png',
            altImg: 'shoko_logo1',
          })}
          {renderCooperator({
            url: '/images/matching/shoko_logo2.png',
            altImg: 'shoko_logo2',
          })}
          {renderCooperator({
            url: '/images/matching/shimaneShinkin_logo.png',
            altImg: 'shimaneShinkin_logo',
          })}
          {renderCooperator({
            url: '/images/matching/shimadaShinkin.png',
            altImg: 'shimadaKakegawaShinkin_logo',
          })}
          {renderCooperator({
            url: '/images/matching/shimizu.png',
            altImg: 'shimizu',
          })}
          {renderCooperator({
            url: '/images/matching/shizuokaFinancialGroup.jpg',
            altImg: 'shizuokaFinancialGroup',
          })}
          {renderCooperator({
            url: '/images/matching/fujiShinkin.png',
            altImg: 'fujiShinkin',
          })}
          {renderCooperator({
            url: '/images/matching/shizuokaMaCenter.png',
            altImg: 'shizuokaMaCenter',
          })}
          {renderCooperator({
            url: '/images/matching/fujinomiyaShinkin.jpg',
            altImg: 'fujinomiyaShinkin',
          })}
          {renderCooperator({
            url: '/images/matching/numazuShinkin.jpg',
            altImg: 'numazuShinkin',
          })}
        </CooperatorCompaniesWrapper>
        <WrapperNote>
          <NoteStyled>{t.noteLogo}</NoteStyled>
        </WrapperNote>
      </CooperatorCompanies>
      <Box textAlign={'center'}>
        <Link href={'/lp/bank_2204'} passHref legacyBehavior>
          <StyledInquiriesButton variant={'outlined'} size={'large'} endIcon={<ChevronRightIcon />}>
            <Typography fontWeight={'bold'}>{t.inquiriesFinancial}</Typography>
          </StyledInquiriesButton>
        </Link>
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled(Box)(
  ({ theme }) => `
  padding-top: ${theme.spacing(8)};
  padding-bottom: ${theme.spacing(9)};
  ${theme.breakpoints.down('sm')} {
    padding-top: ${theme.spacing(5.5)};
    padding-bottom: ${theme.spacing(5.5)};
  }
`
)

const StyledInquiriesButton = styled(Button)(
  ({ theme }) => `
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(2)} ${theme.spacing(1.5)};
  }
`
)

const LogoContainer = styled(Box)(
  ({ theme }) => `
  max-width: 404px;
  img {
    max-width: 100%;
  }
  ${theme.breakpoints.down('md')} {
    max-width: 323px;
  }
`
)

const ImageContainer = styled(Box)(
  () => `
  max-width: 364px;
  img {
    max-width: 100%;
  }
`
)

const Title = styled(MultiLineTypography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(40)};
  ${theme.breakpoints.down('md')} {
    font-size: ${theme.typography.pxToRem(32)};
  }
  ${theme.breakpoints.down('sm')} {
    font-size: ${theme.typography.pxToRem(24)};
  }
`
)

const Description = styled(Box)(
  () => `
  max-width: 520px;
`
)

const CooperatorCompaniesWrapper = styled(Stack)(
  ({ theme }) => `
  padding: ${theme.spacing(2)} 0;
  margin-bottom: ${theme.spacing(5)};
  border-radius: ${theme.spacing(0.75)};
  flex-wrap: wrap;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    margin-bottom: ${theme.spacing(3)};
  }
`
)

const CooperatorLogoContainer = styled(Box)(
  ({ theme }) => `
  max-width: 172px;
  height: 91px;
  border-radius: ${theme.spacing(0.75)};
  overflow: hidden;
  background: ${theme.white.base};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
)

const ContentWrapper = styled(Stack)(
  ({ theme }) => `
  align-items: center;
  justify-content: center;
  column-gap: ${theme.spacing(5)};
  padding-bottom: ${theme.spacing(3.5)};

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    padding-bottom: ${theme.spacing(3)};
    row-gap: ${theme.spacing(3.75)};
  }
`
)
const NoteStyled = styled(MultiLineTypography)(
  ({ theme }) => `
    font-weight: 400;
    font-size: ${theme.typography.pxToRem(12)};
    line-height: 175%;
    color: ${theme.tertiary.lightness1};
  `
)
const CooperatorCompanies = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.palette.common.white};
    margin-bottom: ${theme.spacing(3)};
  `
)
const WrapperNote = styled(Box)(
  ({ theme }) => `
    padding-left: ${theme.spacing(2.8)};
    padding-bottom: ${theme.spacing(2.1)};
    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(2)};
    }
  `
)
