import React, { Fragment, useCallback } from 'react'
import { styled, Typography, Box, Stack } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import { ImageFlux } from './ImageFlux'
import { listColumnArticle as t } from 'public/locales/ja/components/elements/listColumnArticle'
import { Button } from 'components/elements/Button'
import { Tag } from 'types/tag'

export type Article = {
  id: number
  slug: string
  imgUrl: string
  tags: Tag[]
  title: string
  date: string
  link: string
}

type ListArticleProps = {
  listArticle: Article[]
}

export const ListColumnArticle: React.FC<React.PropsWithChildren<ListArticleProps>> = ({ listArticle }) => {
  const handleOpenTag = useCallback((e: React.MouseEvent<HTMLDivElement>, link: string) => {
    e.stopPropagation()
    window.open(link, '_blank')
  }, [])

  const formatDateTime = useCallback((date: string) => {
    return moment(date).format('YYYY.MM.DD')
  }, [])

  const renderArticleItem = useCallback(
    (article: Article) => (
      <Box>
        <LinkStyled onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleOpenTag(e, article.link)}>
          <ImageContainer>
            <ImageFlux src={article.imgUrl} alt={'article'} loading={'lazy'} height={'205'} />
          </ImageContainer>
          <Stack direction={'column'} mt={2}>
            <ArticleTagWrapperStyled>
              {article.tags.map((tag: Tag, index: number) => (
                <ArticleTagStyled
                  key={index}
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleOpenTag(e, tag.link)}
                >
                  <ReactMarkdown>{tag.name}</ReactMarkdown>
                </ArticleTagStyled>
              ))}
            </ArticleTagWrapperStyled>
            <ArticleTitleStyled>
              <ReactMarkdown>{article.title}</ReactMarkdown>
            </ArticleTitleStyled>
            <ArticleDateStyled>{formatDateTime(article.date)}</ArticleDateStyled>
          </Stack>
        </LinkStyled>
      </Box>
    ),
    [formatDateTime, handleOpenTag]
  )

  return (
    <ListArticleContainer>
      <WrapperHeadingStyled>
        <HeadingStyled color={'primary'}> {t.heading}</HeadingStyled>
        <SubHeadingStyled variant={'h2'}>{t.column}</SubHeadingStyled>
      </WrapperHeadingStyled>
      <ArticleCWrapperStyled>
        {listArticle.map((article, index) => (
          <Fragment key={index}>{renderArticleItem(article)}</Fragment>
        ))}
      </ArticleCWrapperStyled>
      <Box textAlign={'center'} pt={5} onClick={(e) => handleOpenTag(e, 'https://ma-navigator.com/columns')}>
        <ButtonStyled variant={'outlined'}>
          <ButtonTextStyled>{t.button}</ButtonTextStyled>
          <ArrowForwardIosIcon fontSize={'inherit'} />
        </ButtonStyled>
      </Box>
    </ListArticleContainer>
  )
}

const ListArticleContainer = styled(Box)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(9)};
`
)
const WrapperHeadingStyled = styled(Stack)(
  ({ theme }) => `
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${theme.spacing(4)};
  padding-top: ${theme.spacing(8)};
  ${theme.breakpoints.down('sm')} {
    padding-bottom: ${theme.spacing(2)};
  }
`
)
const HeadingStyled = styled(Typography)(
  ({ theme }) => `
  font-weight: 700;
  font-size: ${theme.spacing(2.5)};
  line-height: 130%;
  text-align: center;
`
)
const SubHeadingStyled = styled(HeadingStyled)(
  ({ theme }) => `
  font-size: ${theme.spacing(5)};
  padding-top: ${theme.spacing(0.6)};
  ${theme.breakpoints.down('sm')} {
    font-size: ${theme.spacing(3)};
  }
`
)
const ArticleCWrapperStyled = styled(Stack)(
  ({ theme }) => `
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing(3)};
  ${theme.breakpoints.down('lg')} {
    justify-content: center;
  }
  ${theme.breakpoints.down('sm')} {
    gap: ${theme.spacing(2)};
  }
`
)
const LinkStyled = styled(Box)(
  () => `
  cursor: pointer;
  max-width: 334px;
`
)
const ArticleTagWrapperStyled = styled(Stack)(
  ({ theme }) => `
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing(0.5)};
`
)
const ArticleTagStyled = styled(Box)(
  ({ theme }) => `
  p {
    cursor: pointer;
    max-width: max-content;
    padding: ${theme.spacing(0.25)} ${theme.spacing(2)};
    border: 1px solid ${theme.tertiary.lightness1};
    border-radius: ${theme.spacing(12.5)};
    font-size: ${theme.spacing(1.5)};
    margin: 0;
  }
`
)
const ArticleTitleStyled = styled(Box)(
  ({ theme }) => `
    p {
      font-weight: 400;
      line-height: 175%;
      font-size: ${theme.spacing(2.5)};
      padding: ${theme.spacing(2)} 0;
      white-space: pre-line;
      word-break: break-all;
      margin: 0;
    }
`
)
const ArticleDateStyled = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.spacing(1.5)};
  color: ${theme.tertiary.lightness1};
  font-weight: 400;
  line-height: 175%;
`
)
const ButtonStyled = styled(Button)(
  ({ theme }) => `
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
`
)
const ButtonTextStyled = styled(Typography)(
  ({ theme }) => `
  font-weight: 700;
  font-size: ${theme.spacing(2.25)};
  line-height: 150%;
  padding-right: ${theme.spacing(2)};
`
)

const ImageContainer = styled(Box)(
  () => `
  height: 205px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
)
