import React, { useEffect, useMemo, useState } from 'react'
import { Box, styled, Typography, useMediaQuery, useTheme, Stack } from '@mui/material'
import { useIndustries } from 'hooks/useIndustries'
import { ImageFlux } from 'components/elements/ImageFlux'
import { ImageFallback } from 'components/elements/ImageFallBack'
import { Project, ProjectStatus } from 'types/project'
import { INDUSTRY_SETTING } from 'const/industry'

const HEIGHT_BREAK_POINT = '200' as const

type ImageStyle = {
  width?: string
  height: string
  widthMobile?: string
  heightMobile: string
}

type ProjectImageProps = {
  isAdmin?: boolean
  imageStyle: ImageStyle
  ogpImageFile?: File | string
  project: Pick<Project, 'id' | 'code' | 'hasOgpImg' | 'status' | 'presetImageUrl' | 'industryId'> & {
    title?: string | null
  }
  forceShowIndustry?: true
  showIcon?: true
  showTitle?: boolean
}

export const ProjectImage: React.FC<React.PropsWithChildren<ProjectImageProps>> = ({
  isAdmin,
  imageStyle,
  ogpImageFile,
  project,
  forceShowIndustry,
  showIcon,
  showTitle,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const industries = useIndustries()
  const [ogpImage, setOgpImage] = useState<string>('')

  const isPublished = useMemo(
    () => [ProjectStatus.Published, ProjectStatus.Limited, ProjectStatus.Completed].includes(project.status),
    [project.status]
  )

  const ogpImageUrl = useMemo(() => {
    if (isAdmin) return `/api/admin/projects/${project.code}/ogp-img`
    if (isPublished) return `/api/projects/${project.id}/ogp-image/publish`
    return `/api/projects/${project.id}/ogp-image`
  }, [isAdmin, isPublished, project.id, project.code])

  useEffect(() => {
    if (project.hasOgpImg) setOgpImage(ogpImageUrl)
    if (project.presetImageUrl) setOgpImage(project.presetImageUrl)
    if (!ogpImageFile) return
    if (typeof ogpImageFile === 'string') return setOgpImage(ogpImageFile)
    const fileReader = new FileReader()
    fileReader.onload = () => setOgpImage(fileReader.result as string)
    fileReader.readAsDataURL(ogpImageFile)
  }, [ogpImageFile, project.presetImageUrl, project.hasOgpImg, ogpImageUrl])

  const industryIcon = useMemo(
    () => INDUSTRY_SETTING.find((item) => item.id === project.industryId)?.icon,
    [project.industryId]
  )

  const industryName = useMemo(
    () => industries.find((item) => item.id === project.industryId)?.name,
    [project.industryId, industries]
  )

  const isLarge = useMemo(() => imageStyle.height > HEIGHT_BREAK_POINT, [imageStyle.height])

  const isShowIndustryInfo = useMemo(
    () =>
      forceShowIndustry ||
      (ogpImage && !(ogpImageFile instanceof File) && industryIcon && industryName) ||
      (isMobile && industryName),
    [ogpImage, ogpImageFile, industryIcon, industryName, forceShowIndustry, isMobile]
  )
  const renderOgpImage = useMemo(() => {
    if (project && project.hasOgpImg) {
      return (
        <ImageFallback
          url={ogpImageUrl}
          width={isMobile ? imageStyle.widthMobile : imageStyle.width}
          height={isMobile ? imageStyle.heightMobile || '92' : imageStyle.height || '127'}
          alt={'ogpImage'}
          isPrivate={!isPublished}
        />
      )
    }
    if (ogpImageFile && ogpImageFile instanceof File) {
      return <OgpImageFile src={ogpImage} alt={ogpImage} loading="lazy" />
    }
    if (project && !project.hasOgpImg && ogpImage) {
      return (
        <>
          <ImageFlux
            src={ogpImage}
            width={isMobile ? imageStyle.widthMobile : imageStyle.width}
            height={isMobile ? imageStyle.heightMobile || '92' : imageStyle.height || '127'}
            alt={ogpImage}
          />
        </>
      )
    }
  }, [
    project,
    ogpImageFile,
    ogpImage,
    isMobile,
    imageStyle.widthMobile,
    imageStyle.width,
    imageStyle.heightMobile,
    imageStyle.height,
    ogpImageUrl,
    isPublished,
  ])

  const renderIndustry = useMemo(() => {
    if (showTitle) {
      return (
        <AbsoluteIndustryNameBox>
          <Typography fontWeight="bold" fontSize={{ xs: 20, sm: 30 }} py={1}>
            {project.title}
          </Typography>
        </AbsoluteIndustryNameBox>
      )
    }
    if (showIcon) {
      return (
        <ContentImage>
          <Stack justifyContent="flex-end" height="100%">
            {showIcon && (
              <Stack alignItems={'center'} justifyContent={'center'} height={'100%'}>
                {industryIcon && <ImageFlux src={industryIcon} height={isLarge ? '100' : '40'} alt={industryIcon} />}
              </Stack>
            )}
            <IndustryNameBox>
              <Typography fontWeight="bold">{industryName}</Typography>
            </IndustryNameBox>
          </Stack>
        </ContentImage>
      )
    }
    if (isLarge) {
      return (
        <AbsoluteIndustryNameBox py={2}>
          <Typography fontWeight="bold" fontSize={24}>
            {industryName}
          </Typography>
        </AbsoluteIndustryNameBox>
      )
    }
    return (
      <AbsoluteIndustryNameBox>
        <Typography fontWeight="bold">{industryName}</Typography>
      </AbsoluteIndustryNameBox>
    )
  }, [isLarge, showIcon, industryName, industryIcon])

  return (
    <ImageCardTopStyled height={imageStyle.height}>
      <WrapperImageOgp>{renderOgpImage}</WrapperImageOgp>
      {isShowIndustryInfo && renderIndustry}
    </ImageCardTopStyled>
  )
}

const ImageCardTopStyled = styled(Box)<{ height: string }>(
  ({ height }) => `
     position: relative;
     text-align: center;
     width: 100%;
     height: ${height}px;
  `
)
const ContentImage = styled(Box)(
  ({ theme }) => `
    position: absolute;
    background: ${theme.white.lightness4};
    width: 100%;
    top: 0;
    bottom: 0;
  `
)
const WrapperImageOgp = styled(Box)(
  () => `
    width: 100%;
    height: 100%;
    img:first-of-type {
      width: 100%;
      object-fit: cover;
    }
  `
)
const OgpImageFile = styled('img')(
  () => `
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
  `
)
const IndustryNameBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.white.lightness1};
    opacity: 0.8;
  `
)
const AbsoluteIndustryNameBox = styled(IndustryNameBox)(
  () => `
    position: absolute;
    bottom: 0;
    width: 100%;
  `
)
