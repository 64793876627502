import React, { useCallback, useState } from 'react'
import Link from 'next/link'
import { Box, Typography, Button, styled, Stack, useTheme, useMediaQuery } from '@mui/material'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import isEmpty from 'lodash/isEmpty'
import { UsageFee } from 'components/elements/UsageFee'
import { ImageFlux } from 'components/elements/ImageFlux'

import { Industry } from 'types/industry'
import { ProjectMatching } from 'types/project'
import { User } from 'types/user'
import { home as t } from 'public/locales/ja/components/pages/home'
import { contrivance } from 'utils/fixtures/matching/contrivance'
import { MatchingContrivance } from 'components/elements/MatchingContrivance'
import { MultiLineTypography } from 'components/elements/MultiLineTypography'
import { FAQList, Position } from 'components/elements/FAQList'
import { CarouselUserVoices } from 'components/elements/CarouselUserVoices'
import { usersVoices } from 'utils/fixtures/matching/usersVoices'
import { NotificationSection } from 'components/elements/NotificationSection'
import { ListColumnArticle, Article } from 'components/elements/ListColumnArticle'
import { IndustrySearch } from 'components/pages/home/IndustrySearch'
import { MediaSection } from 'components/elements/MediaSection'
import { FinancialInstitutionServices } from 'components/pages/home/FinancialInstitutionServices'
import { ModalRequestAction } from 'components/elements/ModalRequestAction'
import { NewProjectDealCard } from 'components/pages/home/NewProjectDealCard'
import { useNewses } from 'hooks/useNews'
import { INDUSTRY_SETTING } from 'const/industry'

type HomeProps = {
  user: User | null
  articleList: Article[]
  newProjects: ProjectMatching[]
  industries: Industry[]
  userFavoriteIds?: number[]
  onClickFavorite: (projectId: number) => void
}

const ORDER_INDUSTRIES = [3, 8, 9, 4, 14, 7, 2, 15]

export const Home: React.FC<React.PropsWithChildren<HomeProps>> = ({
  user,
  articleList,
  newProjects,
  industries,
  userFavoriteIds,
  onClickFavorite,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const { newses } = useNewses()

  const handleCloseModal = useCallback(() => {
    setIsShowModal(false)
  }, [])

  const handleClickFavorite = useCallback(
    (projectId: number) => {
      if (isEmpty(user)) {
        setIsShowModal(true)
        return
      }
      onClickFavorite(projectId)
    },
    [onClickFavorite, user]
  )

  const filteredIndustries = ORDER_INDUSTRIES.map((id) => industries.filter((industry) => industry.id === id)).flat()

  return (
    <>
      <Box>
        {/*Banner Hero*/}
        <BannerWrapper>
          <BannerContainer>
            <BannerContentWrapper direction={'row'} alignItems={'center'}>
              <Stack paddingTop={{ xs: 0, md: 1.5 }} rowGap={{ xs: 4, md: 6.5 }}>
                <Box>
                  <Description fontWeight={'bold'}>{t.heroBanner.subTitle}</Description>
                  <BannerTitle variant={'h1'}>{t.heroBanner.title}</BannerTitle>
                </Box>
                {!user && (
                  <Link href={'/users/sign_up'} passHref legacyBehavior>
                    <FreeTryButton variant={'outlined'}>
                      <Typography fontWeight={'bold'} fontSize={18}>
                        {t.button.signUp}
                      </Typography>
                    </FreeTryButton>
                  </Link>
                )}
              </Stack>
              <ImageContainer>
                <ImageFlux
                  src={'/images/matching/new_registration.png'}
                  height={`${isMobile ? '200' : '450'}`}
                  width={`${isMobile ? '307' : '691'}`}
                  alt="new_registration"
                  loading={'eager'}
                />
              </ImageContainer>
            </BannerContentWrapper>
          </BannerContainer>
        </BannerWrapper>

        {/*matching contrivance */}
        <MatchingContrivanceWrapper>
          <Box pb={9.5}>
            <Typography fontWeight={'bold'} fontSize={18} align={'center'} gutterBottom color={'primary'}>
              {t.matchingContrivance.nextButton}
            </Typography>
            <Title variant={'h2'} align={'center'} sx={{ pb: 3 }}>
              {t.matchingContrivance.title}
            </Title>
            <MultiLineTypography align={'center'}>{t.matchingContrivance.description}</MultiLineTypography>
          </Box>
          <MatchingContrivanceContainer direction={'row'} justifyContent={'center'}>
            {contrivance.map((item) => (
              <MatchingContrivance
                key={item.title}
                imgUrl={item.imgUrl}
                altImg={item.altImg}
                title={item.title}
                description={item.description}
                imgPosition={item.imgPosition}
                width={item.width}
                height={item.height}
              />
            ))}
          </MatchingContrivanceContainer>
        </MatchingContrivanceWrapper>

        {/* search by new deals */}
        <NewProjectsWrapper>
          <Container>
            <Title variant={'h2'} align={'center'}>
              {t.newProjectsDeal.title}
            </Title>
            <ListProjectContainer flexWrap={'wrap'} direction={'row'} alignItems={'center'} gap={5}>
              {newProjects.map((project) => (
                <NewProjectDealCard
                  userFavoriteIds={userFavoriteIds}
                  key={project.id}
                  project={project}
                  onClickFavorite={handleClickFavorite}
                  role={user?.role}
                />
              ))}
            </ListProjectContainer>
            <Box textAlign={'center'}>
              <Link href={'/projects'} passHref legacyBehavior>
                <StyledSeeMoreButton variant={'outlined'} size={'large'} endIcon={<ChevronRightIcon />}>
                  <Typography fontWeight={'bold'}>{t.button.seeMore}</Typography>
                </StyledSeeMoreButton>
              </Link>
            </Box>
          </Container>
        </NewProjectsWrapper>

        {/* Modal registration information */}
        <ModalRequestAction
          isOpen={isShowModal}
          onClose={handleCloseModal}
          title={t.modalRequestAction.registrationInfo.title}
          buttonTitle={t.modalRequestAction.registrationInfo.buttonTitle}
          buttonUrl="/buyer/sign_up"
          otherLinkTitle={t.modalRequestAction.registrationInfo.otherLinkTitle}
          imageUrl="/images/matching/account_registration.png"
          otherLinkUrl="/users/sign_in"
        />

        {/* Industries search section */}
        <IndustriesSearchWrapper>
          <Container>
            <Stack position={'relative'} pb={{ xs: 5, md: 8 }} alignItems={'center'} justifyContent={'center'}>
              <Title variant={'h2'}>{t.industriesSearch.title}</Title>
              <Link href={'/projects'} passHref legacyBehavior>
                <SetDetailsSearchCondition>
                  <Typography sx={{ textDecoration: 'underline' }} fontWeight={'bold'} color={'primary'}>
                    {t.industriesSearch.setDetails}
                  </Typography>
                </SetDetailsSearchCondition>
              </Link>
            </Stack>
            <IndustriesSearchContainer direction={'row'} alignItems={'center'} justifyContent={'center'}>
              {filteredIndustries.map((industry) => {
                const icon = INDUSTRY_SETTING.find((item) => item.id === industry.id)?.icon || ''
                return (
                  <IndustrySearch
                    key={industry.id}
                    searchUrl={`/projects?industries=${industry.id}`}
                    imageUrl={icon}
                    altImg={icon}
                    industryName={industry.name}
                  />
                )
              })}
            </IndustriesSearchContainer>
          </Container>
        </IndustriesSearchWrapper>
        {/* CarouseUserVoices */}
        <Wrapper>
          <CarouselUserVoices usersVoices={usersVoices} />
        </Wrapper>

        {/*Examples of buyer fees */}
        <Container pt={9}>
          <UsageFee />
        </Container>

        {/* Media Section */}
        <MediaSection />

        {/* List feature articles */}
        <Wrapper>
          <Container>
            <ListColumnArticle listArticle={articleList} />
          </Container>
        </Wrapper>

        {/* notification section */}
        <NotificationSectionWrapper>
          <Container>
            <NotificationSection notifications={newses.slice(0, 4)} />
          </Container>
        </NotificationSectionWrapper>

        {/* Frequently asked questions */}
        <FAQListContainer>
          <Typography variant={'h2'} sx={{ textAlign: 'center' }}>
            {t.matchingFaq.heading}
          </Typography>
          <FAQListWrapper direction={isMobile ? 'column' : 'row'} spacing={5}>
            <Box width={{ xs: '100%', sm: '45%' }}>
              <FAQList
                list={[
                  {
                    title: t.matchingFaq.enterprisesQues,
                    answer: t.matchingFaq.enterprisesAns,
                  },
                  {
                    title: t.matchingFaq.insolventQues,
                    answer: t.matchingFaq.insolventAns,
                  },
                  {
                    title: t.matchingFaq.businessSellQues,
                    answer: t.matchingFaq.businessSellAns,
                  },
                ]}
                title={t.matchingFaq.faqSeller}
                image={'/images/matching/faq/faq_1.png'}
                altImg="faq_1"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '51%' }}>
              <FAQList
                position={Position.RIGHT}
                list={[
                  {
                    title: t.matchingFaq.repurchaseQues,
                    answer: t.matchingFaq.repurchaseAns,
                  },
                  {
                    title: t.matchingFaq.maQues,
                    answer: t.matchingFaq.maAns,
                  },
                  {
                    title: t.matchingFaq.otherPartyQues,
                    answer: t.matchingFaq.otherPartyAns,
                  },
                ]}
                title={t.matchingFaq.faqBuyer}
                image={'/images/matching/faq/faq_2.png'}
                altImg="faq_2"
              />
            </Box>
          </FAQListWrapper>
          <Stack direction={'row'} justifyContent={'center'} sx={{ mt: 5 }}>
            <Link href={'/faq'} passHref legacyBehavior>
              <StyledSeeMoreButton variant={'outlined'} endIcon={<ChevronRightIcon />}>
                <Typography fontWeight={'bold'}>{t.button.seeMore}</Typography>
              </StyledSeeMoreButton>
            </Link>
          </Stack>
        </FAQListContainer>

        {/* Financial institution services */}
        <Container>
          <FinancialInstitutionServices />
        </Container>
      </Box>
    </>
  )
}

const Wrapper = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.blue.base};
`
)

const Container = styled(Box)(
  ({ theme }) => `
  max-width: 1080px;
  margin: 0 auto;
  ${theme.breakpoints.down('lg')} {
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
  }
`
)

const BannerWrapper = styled(Box)(
  ({ theme }) => `
  background-image: url('/images/background-circle.svg');
  background-position: center;
  background-size: cover;
  background-color: ${theme.primary.base};
`
)

const BannerContainer = styled(Box)(
  ({ theme }) => `
  max-width: 1180px;
  margin: 0 auto;
  color: ${theme.palette.common.white};
  padding-top: ${theme.spacing(11)};
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(6)} ${theme.spacing(4)} 0;
  }
  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(6)} ${theme.spacing(2)} 0;
  }
`
)

const BannerContentWrapper = styled(Stack)(
  ({ theme }) => `
  column-gap: ${theme.spacing(3)};
  ${theme.breakpoints.down('lg')} {
    flex-direction: column;
    row-gap: ${theme.spacing(5.5)};
  }
`
)

const BannerTitle = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(50)};
  ${theme.breakpoints.down('md')} {
    font-size: ${theme.typography.pxToRem(32)};
  }
`
)

const Description = styled(Typography)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(2)};
  ${theme.breakpoints.down('md')} {
    font-size: ${theme.typography.pxToRem(16)};
  }
  ${theme.breakpoints.down('sm')} {
    font-size: ${theme.typography.pxToRem(14)};
  }
`
)

const StyledButton = styled(Button)(
  ({ theme }) => `
  &:hover {
    background: ${theme.primary.lightness4};
  }
`
)

const FreeTryButton = styled(StyledButton)(
  ({ theme }) => `
  max-width: 292px;
  width: 100%;
  padding: ${theme.spacing(4.5)} 0;
  ${theme.breakpoints.down('lg')} {
    align-self: center;
  }
  ${theme.breakpoints.down('md')} {
    max-width: 243px;
    padding: ${theme.spacing(3)} 0;
  }
`
)

const ImageContainer = styled(Box)(
  ({ theme }) => `
  max-width: 706px;
  margin-bottom: ${theme.spacing(-10)};
  img {
    max-width: 100%;
    object-fit: cover;
  }
  ${theme.breakpoints.down('md')} {
    align-self: center;
  }
`
)

const MatchingContrivanceWrapper = styled(Container)(
  ({ theme }) => `
  padding-top: ${theme.spacing(14.5)};
`
)

const MatchingContrivanceContainer = styled(Stack)(
  ({ theme }) => `
  column-gap: ${theme.spacing(5)};
  ${theme.breakpoints.down('md')} {
    flex-wrap: wrap;
    row-gap: ${theme.spacing(5)};
    align-items: center;
  }
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`
)

const Title = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(40)};
  ${theme.breakpoints.down('sm')} {
    font-size: ${theme.typography.pxToRem(24)};
  }
`
)

const NotificationSectionWrapper = styled(Box)(
  ({ theme }) => `
  padding-top: ${theme.spacing(8)};
  padding-bottom: ${theme.spacing(9)};
`
)

const IndustriesSearchWrapper = styled(Box)(
  ({ theme }) => `
  padding-top: ${theme.spacing(8)};
  padding-bottom: ${theme.spacing(9)};
  ${Container} {
    padding: 0;
  }
`
)

const IndustriesSearchContainer = styled(Stack)(
  ({ theme }) => `
  flex-wrap: wrap;
  gap: ${theme.spacing(5)};
  ${theme.breakpoints.down('sm')} {
    gap: ${theme.spacing(2)};
  }
`
)

const SetDetailsSearchCondition = styled('a')(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(5)};
  ${theme.breakpoints.down('md')} {
    position: static;
    padding-top: ${theme.spacing(2)};
  }
`
)

const NewProjectsWrapper = styled(Box)(
  ({ theme }) => `
  padding-top: ${theme.spacing(8)};
  padding-bottom: ${theme.spacing(9)};
`
)

const StyledSeeMoreButton = styled(Button)(
  ({ theme }) => `
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(2)} ${theme.spacing(1.5)};
  }
`
)

const ListProjectContainer = styled(Stack)(
  ({ theme }) => `
  padding-top: ${theme.spacing(8)};
  padding-bottom: ${theme.spacing(5)};

  ${theme.breakpoints.down(1140)} {
    justify-content: center;
  }
  ${theme.breakpoints.down('md')} {
    gap: ${theme.spacing(3)};
  }
  ${theme.breakpoints.down('sm')} {
    padding-top: ${theme.spacing(5)};
    padding-bottom: ${theme.spacing(4)};
  }
`
)

const FAQListContainer = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(8)} 0;
  background-color: ${theme.blue.base};
  ${theme.breakpoints.down('lg')} {
    padding: ${theme.spacing(8)} ${theme.spacing(2)};
  }
`
)

const FAQListWrapper = styled(Stack)(
  ({ theme }) => `
  max-width: 1080px;
  margin: 0 auto;
  padding-top: ${theme.spacing(8)};
`
)
