export interface Glossary {
  id: number
  name: string
  nameKana: string
  slug: string
  description: string
  glossaryCategoryId: number
  glossaryCategory: {
    id: number
    name: string
    slug: string
  }
}

export enum GlossaryAlphaNumeric {
  a = 'a',
  ka = 'ka',
  sa = 'sa',
  ta = 'ta',
  na = 'na',
  ha = 'ha',
  ma = 'ma',
  ya = 'ya',
  ra = 'ra',
  wa = 'wa',
  alphanumeric = 'alphanumeric',
}

type GlossaryAlphaResponse = 'a' | 'ka' | 'sa' | 'ta' | 'na' | 'ha' | 'ma' | 'ya' | 'ra' | 'wa' | 'alphanumeric'

export type GlossaryAlpha = Record<GlossaryAlphaResponse, Glossary[]>
