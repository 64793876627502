/* eslint-disable prettier/prettier */
// noinspection NonAsciiCharacters

import { isKatakana } from 'wanakana'

export function toHankakuNumber(value: string): number {
  return Number(
    value.replace(/[０-９]/g, (str) => String.fromCharCode(str.charCodeAt(0) - 0xfee0)).replace(/[‐－―ー]/g, '-')
  )
}

const dakuten = {
  ｶﾞ: 'ガ',
  ｷﾞ: 'ギ',
  ｸﾞ: 'グ',
  ｹﾞ: 'ゲ',
  ｺﾞ: 'ゴ',
  ｻﾞ: 'ザ',
  ｼﾞ: 'ジ',
  ｽﾞ: 'ズ',
  ｾﾞ: 'ゼ',
  ｿﾞ: 'ゾ',
  ﾀﾞ: 'ダ',
  ﾁﾞ: 'ヂ',
  ﾂﾞ: 'ヅ',
  ﾃﾞ: 'デ',
  ﾄﾞ: 'ド',
  ﾊﾞ: 'バ',
  ﾊﾟ: 'パ',
  ﾋﾞ: 'ビ',
  ﾋﾟ: 'ピ',
  ﾌﾞ: 'ブ',
  ﾌﾟ: 'プ',
  ﾍﾞ: 'ベ',
  ﾍﾟ: 'ペ',
  ﾎﾞ: 'ボ',
  ﾎﾟ: 'ポ',
  ﾜﾞ: 'ヷ',
  ｳﾞ: 'ヴ',
  ｦﾞ: 'ヺ',
}
const zenkaku =
  '。「」、・ヲァィゥェォャュョッーアイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワン'

const hankaku = {
  ァ: 'ｧ',
  ア: 'ｱ',
  ィ: 'ｨ',
  イ: 'ｲ',
  ゥ: 'ｩ',
  ウ: 'ｳ',
  ェ: 'ｪ',
  エ: 'ｴ',
  ォ: 'ｫ',
  オ: 'ｵ',
  カ: 'ｶ',
  ガ: 'ｶﾞ',
  キ: 'ｷ',
  ギ: 'ｷﾞ',
  ク: 'ｸ',
  グ: 'ｸﾞ',
  ケ: 'ｹ',
  ゲ: 'ｹﾞ',
  コ: 'ｺ',
  ゴ: 'ｺﾞ',
  サ: 'ｻ',
  ザ: 'ｻﾞ',
  シ: 'ｼ',
  ジ: 'ｼﾞ',
  ス: 'ｽ',
  ズ: 'ｽﾞ',
  セ: 'ｾ',
  ゼ: 'ｾﾞ',
  ソ: 'ｿ',
  ゾ: 'ｿﾞ',
  タ: 'ﾀ',
  ダ: 'ﾀﾞ',
  チ: 'ﾁ',
  ヂ: 'ﾁﾞ',
  ッ: 'ｯ',
  ツ: 'ﾂ',
  ヅ: 'ﾂﾞ',
  テ: 'ﾃ',
  デ: 'ﾃﾞ',
  ト: 'ﾄ',
  ド: 'ﾄﾞ',
  ナ: 'ﾅ',
  ニ: 'ﾆ',
  ヌ: 'ﾇ',
  ネ: 'ﾈ',
  ノ: 'ﾉ',
  ハ: 'ﾊ',
  バ: 'ﾊﾞ',
  パ: 'ﾊﾟ',
  ヒ: 'ﾋ',
  ビ: 'ﾋﾞ',
  ピ: 'ﾋﾟ',
  フ: 'ﾌ',
  ブ: 'ﾌﾞ',
  プ: 'ﾌﾟ',
  ヘ: 'ﾍ',
  ベ: 'ﾍﾞ',
  ペ: 'ﾍﾟ',
  ホ: 'ﾎ',
  ボ: 'ﾎﾞ',
  ポ: 'ﾎﾟ',
  マ: 'ﾏ',
  ミ: 'ﾐ',
  ム: 'ﾑ',
  メ: 'ﾒ',
  モ: 'ﾓ',
  ャ: 'ｬ',
  ヤ: 'ﾔ',
  ュ: 'ｭ',
  ユ: 'ﾕ',
  ョ: 'ｮ',
  ヨ: 'ﾖ',
  ラ: 'ﾗ',
  リ: 'ﾘ',
  ル: 'ﾙ',
  レ: 'ﾚ',
  ロ: 'ﾛ',
  ヮ: 'ﾜ',
  ワ: 'ﾜ',
  ヰ: 'ｲ',
  ヱ: 'ｴ',
  ヲ: 'ｦ',
  ン: 'ﾝ',
  ヴ: 'ｳﾞ',
  ヷ: 'ﾜﾞ',
  ヸ: 'ｲﾞ',
  ヹ: 'ｴﾞ',
  ヺ: 'ｦﾞ',
  '・': '･',
  ー: 'ｰ',
  '。': '｡',
  '「': '｢',
  '」': '｣',
  '、': '､',
}

export function toKanaHalfWidth(value: string): string {
  return value.replace(/[\u3002\u300c\u300d\u3001\u30a1-\u30fc]/g, function (str) {
    // @ts-ignore
    return hankaku[str]
  })
}
function toKanaFullWidth(value: string): string {
  const dakutenRegExp = new RegExp('(' + Object.keys(dakuten).join('|') + ')', 'g')
  return value
    .replace(dakutenRegExp, function (str) {
      // @ts-ignore
      return dakuten[str]
    })
    .replace(/[\uff61-\uff9d]/g, function (str) {
      return zenkaku[str.charCodeAt(0) - 0xff61]
    })
}

function toKatanakafromHiragana(value: string): string {
  return value.replace(/[\u3041-\u3096]/g, (match) => {
    const chr = match.charCodeAt(0) + 0x60
    return String.fromCharCode(chr)
  })
}

export function toHalfWidthKanaFromHiragana(value: string): string {
  return toKanaHalfWidth(toKatanakafromHiragana(value))
}

export function isKatakanaHalfWidth(value: string): boolean {
  return isKatakana(toKanaFullWidth(value))
}
