import React from 'react'
import { Box, styled, Typography, Stack, useTheme } from '@mui/material'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { CustomArrowProps } from 'react-slick'
import { Slick } from 'components/modules/Slick'
import { ImageFlux } from 'components/elements/ImageFlux'
import { UserRole } from 'types/user'
import { carouselUserVoices as t } from 'public/locales/ja/components/elements/carouselUserVoices'

interface UserVoicesType {
  icon: string
  userType: string
  userName: string
  age: string
  location: string
  comment: string
  role: number
}

interface CarouselUserVoicesProps {
  usersVoices: UserVoicesType[]
}

const NextArrow = (props: CustomArrowProps) => {
  return <ArrowCircleRightIcon className={props.className} onClick={props.onClick} />
}

const PrevArrow = (props: CustomArrowProps) => {
  return <ArrowCircleLeftIcon className={props.className} onClick={props.onClick} />
}

export const CarouselUserVoices: React.FC<React.PropsWithChildren<CarouselUserVoicesProps>> = ({ usersVoices }) => {
  const theme = useTheme()
  const settingsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '300px',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '200px',
        },
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: '20px',
        },
      },
    ],
  }
  return (
    <StyledWrapper>
      <StyledTypographyTitle variant={'h2'}>{t.title}</StyledTypographyTitle>
      <Slick {...settingsSlider}>
        {usersVoices.map((item, index) => (
          <StyledContainer key={index}>
            <StyledCard direction={{ sm: 'row', xs: 'column' }} spacing={3}>
              <Box>
                <ImageContainer>
                  <ImageFlux loading={'lazy'} src={item.icon} alt={'avatar_user'} width={'80'} />
                </ImageContainer>
              </Box>
              <StyledStack gap={1}>
                <StyledUserType
                  color={item.role === UserRole.Seller ? theme.palette.common.white : theme.primary.base}
                  bgcolor={item.role === UserRole.Seller ? theme.primary.base : theme.blue.base}
                >
                  <StyledTypographyUserType variant={'body2'}>{item.userType}</StyledTypographyUserType>
                </StyledUserType>
                <Stack direction={{ sm: 'row', xs: 'column' }}>
                  <Typography variant={'h3'} mr={{ sm: 4, xs: 1.5 }}>
                    {item.userName}
                  </Typography>
                  <StyledTypographyAge variant={'body2'}>
                    {item.age} / {item.location}
                  </StyledTypographyAge>
                </Stack>
                <Typography variant={'body2'}>{item.comment}</Typography>
              </StyledStack>
            </StyledCard>
          </StyledContainer>
        ))}
      </Slick>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Stack)(
  ({ theme }) => `
  padding: ${theme.spacing(8)} 0;
  overflow: hidden;
  ${theme.breakpoints.up('sm')} {
    &:hover {
      .slick-prev {
        left: 25px;
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev,
      .slick-next {
        z-index: 1000;
        color: ${theme.primary.lightness2};
        height: 56px;
        width: 56px;
      }
    }
  }`
)

const StyledTypographyTitle = styled(Typography)(
  ({ theme }) => `
  text-align: center;
  padding-bottom: ${theme.spacing(5)};
  ${theme.breakpoints.up('sm')} {
    font-size: ${theme.typography.pxToRem(40)};
    padding-bottom: ${theme.spacing(8)};
  }
`
)

const StyledTypographyUserType = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(12)};
`
)

const StyledContainer = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(1.5)};
`
)

const StyledCard = styled(Stack)(
  ({ theme }) => `
  background: ${theme.palette.common.white};
  width: 100%;
  padding: ${theme.spacing(3)};
  min-height: 196px;
  ${theme.breakpoints.down('sm')} {
    border-radius: 6px;
    align-items: center;
  }
`
)

const StyledStack = styled(Stack)(
  ({ theme }) => `
  ${theme.breakpoints.down('sm')} {
    align-items: center;
  }
`
)
const StyledUserType = styled(Box)(
  ({ theme }) => `
  max-width: max-content;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  padding: ${theme.spacing(0.5)} ${theme.spacing(1.25)}
`
)

const StyledTypographyAge = styled(Typography)(
  ({ theme }) => `
  color: ${theme.tertiary.lightness1};
  font-size: ${theme.typography.pxToRem(13)};
  text-align: center;
  ${theme.breakpoints.down('sm')} {
    margin-top: ${theme.spacing(1)}
  }
`
)

const ImageContainer = styled(Box)(
  () => `
    width: 80px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
    }
  `
)
