import filter from 'lodash/filter'
import isNumber from 'lodash/isNumber'
import { PROJECT_PUBLISH_RANGE } from 'shared/const/project'
import { CorporateClassification, EmployeeNum } from 'types/company'
import {
  ProjectBuyer,
  ProjectOrderOption,
  ProjectOwnerOption,
  ProjectPickup,
  ProjectRelatedCompany,
  ProjectScheme,
  ProjectStatus,
  ProjectTakeOver,
  ProjectTransactionTime,
  ProjectUseOption,
  ProjectWhen,
} from 'types/project'
import { UserIdentificationStatus, UserRole } from 'types/user'
import { GlossaryAlphaNumeric } from 'types/glossary'
import { ProjectInquiryOptions } from 'types/contact'
import { DisclosureRange, DataSourceKind } from 'types/linkDataSource'

const now = new Date()
const currentYear = now.getFullYear()

export const yearOptions = Array(currentYear - 1900 + 1)
  .fill(null)
  .map((_null, i) => currentYear - i)

export const recentPastYearOptions = Array(5)
  .fill(null)
  .map((_null, i) => currentYear - i)

export const futureYearOptions = Array(5)
  .fill(null)
  .map((_null, i) => currentYear + i)

export const monthOptions = Array(12)
  .fill(null)
  .map((_null, i) => i + 1)

export const dateOptions = Array(31)
  .fill(null)
  .map((_null, i) => i + 1)

export const hourOptions = Array(24)
  .fill(null)
  .map((_null, i) => i)

export const minuteOptions = Array(60)
  .fill(null)
  .map((_null, i) => i)

export const relatedCompanyOptions = [0, 1]

export const corporateClassifications = Object.keys(CorporateClassification)
  .map(Number)
  .filter((c) => !isNaN(c))

export const projectStatusOptions = Object.keys(ProjectStatus)
  .map(Number)
  .filter((s) => !isNaN(s))

export const projectPickupOptions = Object.keys(ProjectPickup)
  .map(Number)
  .filter((p) => !isNaN(p))

export const projectPublishRangeOptions = Object.values(PROJECT_PUBLISH_RANGE).map(Number)

export const projectRelatedCompanyOptions = Object.keys(ProjectRelatedCompany)
  .map(Number)
  .filter((c) => !isNaN(c))

export const useOptions = Object.keys(ProjectUseOption)
  .map(Number)
  .filter((u) => !isNaN(u))

export const ownerOptions = Object.keys(ProjectOwnerOption)
  .map(Number)
  .filter((o) => !isNaN(o))

export const projectOrderOptions = Object.values(ProjectOrderOption)

export const glossaryAlphaOptions = Object.values(GlossaryAlphaNumeric)

export const inquiryOptions = Object.keys(ProjectInquiryOptions)
  .map(Number)
  .filter((i) => !isNaN(i))

export const salesOptions = [
  0, 1000, 5000, 10000, 50000, 100000, 250000, 500000, 1000000, 2500000, 5000000, 10000000, 25000000, 50000000,
]

export const profitOptions = [-100000, 0, 300, 3000, 5000, 10000, 20000, 50000, 100000, 200000, 300000, 500000]

export const schemeOptions = filter(ProjectScheme, isNumber)
export const whenOptions = filter(ProjectWhen, isNumber)
export const takeOverPeriodOptions = filter(ProjectTakeOver, isNumber)
export const buyerOptions = filter(ProjectBuyer, isNumber)
export const transactionTimeOptions = filter(ProjectTransactionTime, isNumber)
export const employeeNumOptions = filter(EmployeeNum, isNumber)

export const disclosureRangeOptions = filter(DisclosureRange, isNumber)

export const dataSourceKindOptions = filter(DataSourceKind, isNumber)

export const roleFilterOptions = [UserRole.Buyer, UserRole.Seller, UserRole.Coordinator]

export const identificationFilterOptions = filter(UserIdentificationStatus, isNumber)

const rolesBanker = [
  UserRole.BankOwner,
  UserRole.BankAdmin,
  UserRole.BankHQManager,
  UserRole.BankHQMember,
  UserRole.BankBranchManager,
  UserRole.BankBranchMember,
  UserRole.BankHandsOn,
  UserRole.BankReadOnlyBranchMember,
]
export const filteredBankerRoles = [
  UserRole.BankOwner,
  UserRole.BankAdmin,
  UserRole.BankHQManager,
  UserRole.BankHQMember,
  // UserRole.BankBranchMember,
  // UserRole.BankReadOnlyBranchMember,
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getOptionsRoles = (manageRoles: UserRole[] | undefined) => {
  if (!manageRoles) return rolesBanker
  return rolesBanker.filter((role) => manageRoles.includes(role))
}
const HUNDRED = 10 ** 2
const THOUSAND = 10 ** 3
const MILLION = 10 ** 6
const BILLION = 10 ** 9

export const projectSalesAmount = [
  [0, 10 * THOUSAND - 1],
  [10 * THOUSAND, 50 * THOUSAND],
  [50 * THOUSAND, 100 * THOUSAND],
  [100 * THOUSAND + 1, BILLION],
]

export const projectPrices = [
  [0, HUNDRED - 1],
  [HUNDRED, 3 * HUNDRED - 1],
  [3 * HUNDRED, 5 * HUNDRED - 1],
  [5 * HUNDRED, 7.5 * HUNDRED - 1],
  [7.5 * HUNDRED, 10 * HUNDRED - 1],
  [10 * HUNDRED, 20 * HUNDRED - 1],
  [20 * HUNDRED, 30 * HUNDRED - 1],
  [30 * HUNDRED, 50 * HUNDRED - 1],
  [50 * HUNDRED, 75 * HUNDRED - 1],
  [75 * HUNDRED, 100 * HUNDRED - 1],
  [100 * HUNDRED, 250 * HUNDRED - 1],
  [250 * HUNDRED, 500 * HUNDRED - 1],
  [500 * HUNDRED, 100 * THOUSAND - 1],
  [100 * THOUSAND, 10 * MILLION],
]
