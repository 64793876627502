export const validation = {
  required: '{{title}}を入力してください',
  getRequired: (title: string): string => `${title}を入力してください`,
  string: {
    min: '{{min}}文字以上で入力してください',
    max: '{{max}}文字以下で入力してください',
    maxDraft: '下書きに登録できる{{title}}は{{max}}字までです',
    katakana: 'カタカナで入力してください',
    getMin: (min: number): string => `${min}文字以上で入力してください`,
    getMax: (max: number): string => `${max}文字以下で入力してください`,
    getMaxDraft: (title: string, max: number): string => `下書きに登録できる${title}は${max}字までです`,
  },
  number: {
    NaN: '数値を入力してください',
    positive: '正の数値を入力してください',
    min: '{{min}}以上の数を入力してください',
    max: '{{max}}以下の数を入力してください',
    getMin: (min: number | string): string => `${min}以上の数を入力してください`,
    getMax: (max: number | string): string => `${max}万円以下の数を入力してください`,
    getMaxDigitsUnit: (max: number): string => `${max}億円以下の数を入力してください`,
    getMaxNumber: (max: number): string => `${max}以下の数を入力してください`,
    limit: '99,999,999,999',
    range: '上限には下限より大きい数値を入力してください',
  },
  email: {
    placeholder: '例）mail@example.com',
    format: 'メールアドレスの形式が不正です',
    notfound: '入力されたメールアドレスはシステムに登録されていません。',
    duplicate: 'すでに登録済みのメールアドレスです',
    locked: 'このアカウントはロックされているためログインできません。管理者にロック解除を依頼してください',
    required: 'メールアドレスが入力されていません',
  },
  phone: {
    format: '電話番号（半角数字またはハイフン）のみ入力可能です',
  },
  date: {
    format: '{{title}}有効な形式で入力してください。',
    getFormat: (title: string): string => `${title}有効な形式で入力してください。`,
  },
  url: {
    format: 'URLの形式が不正です',
  },
  password: {
    label: 'パスワード',
    format: '半角英数字、半角記号（!@#$%^&*()_+|~-=?[]{}()/）でご入力ください',
    bankerFormat: '半角大文字、半角小文字、半角数字、半角記号（!@#$%^&*_+|~-=?[]{}()\\/）を最低1文字以上含めてください',
    confirmation: 'パスワード（確認）が一致しません',
  },
  file: {
    accepted: {
      empty: 'データが入力されていません。',
    },
    type: 'ファイル形式が不正です',
    maxSize: '{{max}}MB以下のファイルを選択してください',
    sheetNameTooLong: 'シート名を31文字以下で入力してください。',
    getMaxSize: (max: number): string => `${max}MB以下のファイルを選択してください`,
  },
  zipcode: {
    format: '郵便番号の形式が不正です',
  },
  code: {
    duplicate: '店番が既に登録されています。',
  },
  autoComplete: {
    users: '{{title}}は{{max}}名までです',
    usersDraft: '名までです下書きに登録できる{{title}}は{{max}}名までです',
    getUsers: (title: string, max: number): string => `${title}は${max}名までです`,
    getUsersDraft: (title: string, max: number): string => `名までです下書きに登録できる${title}は${max}名までです`,
  },
  checkBox: {
    required: {
      needs: 'この項目は必須です。',
    },
  },
  commission: {
    uniqueConstraint: '１つのニーズに同じ報酬種別を複数登録することはできません。',
  },
  id: {
    slackChannelId: 'SlackチャンネルIDは空白文字以外の半角英数字で入力してください',
  },
}
