const ONLY_PATHS = [
  '/',
  '/users/sign_in',
  '/users/sign_up',
  '/buyer/sign_in',
  '/buyer/sign_up',
  '/seller/sign_in',
  '/seller/sign_up',
]

const patterns = [
  { regex: /^\/projects\/industries\/(\d+)$/, format: (id: string) => `/projects?industries=${id}` },
  {
    regex: /^\/features\/\d+(\?.*)?$/,
    format: (path: string) => path,
  },
  {
    regex: /^\/projects\/\d+(\?.*)?$/,
    format: (path: string) => path,
  },
]

const handleQueryParams = (rawQuery: string): string => {
  const queryParams = new URLSearchParams(rawQuery)
  if (queryParams.get('commit')) {
    queryParams.delete('commit')
  }

  queryParams.forEach((value, key) => {
    if (!value) {
      queryParams.delete(key)
    }
  })

  return queryParams.toString()
}

const handlePatterns = (fullPath: string): string | null => {
  for (const { regex, format } of patterns) {
    const match = fullPath.match(regex)
    if (match) {
      return format(match[1])
    }
  }
  return null
}

export const formatCanonicalPath = (fullPath: string): string => {
  if (fullPath === undefined) {
    return '/'
  }

  const [path, rawQuery] = fullPath.split('?')

  if (ONLY_PATHS.includes(path)) {
    return path
  }

  const formattedPath = handlePatterns(fullPath)
  if (formattedPath) {
    return formattedPath
  }

  if (rawQuery) {
    const filteredQuery = handleQueryParams(rawQuery)
    return filteredQuery ? `${path}?${filteredQuery}` : path
  }

  return fullPath
}
