import useSWR from 'swr'
import type { Industry } from 'types/industry'
import { INDUSTRY_SETTING } from 'const/industry'
import { IndustryPreset } from 'types/industry'

export function useIndustries(): Industry[] {
  const { data } = useSWR<Industry[]>('/api/industries')
  return !data ? [] : data
}

export function useIndustryPresets(): IndustryPreset[] {
  const industries = useIndustries()
  return INDUSTRY_SETTING.map((item) => {
    const name = industries.find((industry) => industry.id === item.id)?.name || ''
    return item.presets.map((preset) => ({
      industryId: item.id,
      name,
      preset,
      icon: item.icon,
    }))
  }).flat()
}
