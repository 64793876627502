import BigNumber from 'bignumber.js'
import { isNumber, findLast, get } from 'lodash'
import { digitsUnitsMapsLabel } from 'components/modules/Currency'
import { projects } from 'public/locales/ja/model/projects'
import { EMPTY_STRING } from 'utils/string'
import { DigitsUnitType } from 'types/user'

export function formatCurrency(n: number, unit = '円', isShowUnit = true): string {
  let result = ''
  const isNegative = n < 0
  const str = `${Math.abs(n)}`
  const digits = str.split('').reverse()
  digits.forEach((d, i) => {
    if (i && i % 3 === 0) {
      result = ',' + result
    }
    result = d + result
  })
  result = isNegative ? '-' + result : result
  return isShowUnit ? `${result} ${unit}` : `${result}`
}

export const toUnitHowMuch = (howMuch?: number | null): string => {
  if (howMuch === null || howMuch === 0) {
    return projects.takeOverPeriod[7]
  }

  let howMuchStr = howMuch ? howMuch.toString() : ''
  if (howMuchStr.length >= 4) {
    howMuchStr = howMuchStr.slice(0, howMuchStr.length - 3) + ',' + howMuchStr.slice(-3)
  }

  if (howMuchStr.length >= 6) {
    howMuchStr = howMuchStr.slice(0, howMuchStr.length - 5) + '億' + howMuchStr.slice(-5)
  }

  let tmpItems = howMuchStr.split('億')
  if (tmpItems[1] == '0,000' && howMuchStr.length <= 10) {
    return tmpItems[0] + '億円'
  }

  if (howMuchStr.length >= 10) {
    howMuchStr = howMuchStr.slice(0, howMuchStr.length - 9) + ',' + howMuchStr.slice(-9)
  }

  if (howMuchStr.length >= 12) {
    howMuchStr = howMuchStr.slice(0, howMuchStr.length - 11) + '兆' + howMuchStr.slice(-11)
  }

  tmpItems = howMuchStr.split('兆')
  if (tmpItems[1] == '0,000億0,000') {
    return tmpItems[0] + '兆円'
  }

  return howMuchStr + projects.howMuch.suffix
}

export const getRangeMin = (value?: number | null, options?: number[]): number => {
  if (!value || !options) return 0
  return findLast(options, (min) => min <= value) || 0
}

type DecimalDigit = 0 | 1 | 2 | 3 | undefined
export const getCurrencyString = (
  digits: DigitsUnitType,
  value?: number | BigNumber | '' | null,
  isShowDigitsUnit?: boolean,
  decimalDigit?: DecimalDigit
): string => {
  if (isNumber(value) && isShowDigitsUnit) {
    return getFormatNumberString(value / 10 ** digits, decimalDigit) + digitsUnitsMapsLabel[digits]
  }

  if (isNumber(value) && !isShowDigitsUnit) {
    return getFormatNumberString(value / 10 ** digits, decimalDigit)
  }

  return EMPTY_STRING
}

const getFormatNumberString = (value: number | bigint, decimalDigit: DecimalDigit): string => {
  return value.toLocaleString(undefined, { maximumFractionDigits: decimalDigit })
}

const digitUnits = {
  0: '千円',
  3: '百万円',
  5: '億円',
}
export const getDigitsUnit = (u: number): string => {
  return get(digitUnits, u)
}
