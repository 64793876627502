export const matchingFaq = {
  title: 'よくある質問 - 【M&Aナビ】M&A・事業承継マッチングプラットフォーム',
  description:
    'M&Aの進め方やM&Aナビの使い方等、お問い合わせの多い質問をご紹介いたします。M&Aナビでは、候補先探しから交渉実務までを一貫してオンラインで進めることができるナビゲーション機能で初めてのM&Aを徹底サポートします。',
  keywords: 'M&A,事業承継,M&A マッチング,M&A プラットフォーム,会社 売却',
  heading: 'よくある質問',
  faqSeller: '売り手の方',
  faqBuyer: '買い手の方',
  foreWord: 'M&Aの進め方やM&Aナビの使い方などについて、お客様からお問い合わせの多い質問を掲載しております。',
  aboutMA: 'M&Aについて',
  aboutManavi: 'M&Aナビについて',
  enterprisesQues: '中小企業であってもM&Aできるのでしょうか？',
  repurchaseQues: 'M&Aによる買収を検討しています。どうしたら良いでしょうか。',
  maQues: 'M&Aナビの利用料はいくらですか？',
  otherPartyQues: 'M&Aナビはどのようなサービスを提供しているのでしょうか？',
  kindOfCompanyQues: '相手の見つかりやすい企業はどのような会社でしょうか？',
  insolventQues: '赤字、債務超過の会社ですが相手は見つかりますか？',
  businessSellQues: '会社の一部の事業だけの売却は可能でしょうか？',
  acquisitionQues: 'M&Aによる買収を検討しています。どうしたらよいでしょうか。',
  maMethodsQues: 'M&Aの手法はどのようなものがありますか？',
  corporateValueQues: '企業価値算定の方法は？',
  salePriceQues: 'M&Aの売却価格はどのように決まるのか？',
  awarenessQues: 'M&Aを成功させるにはどんなことに気をつければ良いのですか？',
  servicesProvideQues: 'M&Aナビはどのようなサービスを提供しているのでしょうか？',
  usageQues: 'M&Aナビの利用料はいくらですか？',
  registerQues: 'M&Aナビへの登録はいつするべきでしょうか？まだM&Aをすると決断はしていません。',
  securityQues: '登録した情報は従業員、取引先、金融機関等の第三者に漏れませんか？',
  negotiateBuyerQues: '買い手候補・売り手企業と直接交渉できますか？',
  userQues: '誰がユーザー登録することができますか？',
  registrationQues: '個人、有限会社、医療法人、合同会社等も登録できますか？',
  restrictionQues: '売却希望企業の登録制限はありますか？',
  recommendQues: '推奨ブラウザはありますか？',
  forgotPasswordQues: 'パスワードがわからない',
  consultationQues: '直接会って相談することはできますか？',
  repurchaseAns:
    'M&Aナビに登録をしていただければ、売却希望企業に交渉リクエストを送信することが可能になります。気になる企業があれば交渉リクエストを送り、売却希望企業が承認すればより具体的な内容（社名や決算数値など）を確認できます。',
  enterprisesAns:
    '中小企業でもM&Aは一般化してきています。特に、事業承継の一手法としてM&Aを活用する中小企業が近年急増しています。中小企業だからM&Aできないという時代ではなくなりました。',
  maAns: 'M&Aナビの利用料は売り手は完全無料、買い手は成約時に費用が発生します。',
  otherPartyAns:
    'M&Aを検討する会社(売却、譲受ともに)、または売却を希望する会社を支援する士業・専門家が、オンラインで候補先マッチングから交渉・契約実務まで行うことができるサービスです。従来M&Aの専門会社がいないとできなかったようなサービスを安価に、気軽に提供いたします。',
  kindOfCompanyAns:
    '時期や条件を除けば、基本的に相手が見つからない企業はありません。希望条件を高望みし過ぎると当然難易度は上がります。まずはM&Aナビにご登録いただき、無料で行える株価算定サービスを使って自社のフェアバリューを知るところからはじめてみてはいかがでしょうか。',
  insolventAns:
    '貴社が買収希望企業にとって魅力ある何かを保有しているのであれば、可能性はあります。まずは登録してお相手探しをしてみましょう。',
  businessSellAns:
    '事業売却や会社分割のスキームを利用することで、会社の一部の事業だけを売却することは可能です。進め方に関してご相談されたい方は、お気軽に当社までお問い合わせください。',
  acquisitionAns:
    'M&Aナビに登録をしていただければ、売却希望企業に交渉リクエストを送信することが可能になります。気になる企業があれば交渉リクエストを送り、売却希望企業が承認すればより具体的な内容（社名や決算数値など）を確認できます。',
  maMethodsAns:
    '株式売却、事業売却、会社分割をいずれかのスキームを採用するケースが多いです。ケースによっては、株式交換や合併スキームで行うこともあります。',
  corporateValueAns:
    'M&Aの企業価値算定方法は、一般的に以下のいずれかによって算定します。\n・時価純資産に着目した評価方法(コスト・アプローチ)\n・収益やキャッシュフローに着目した評価方法(インカム・アプローチ)\n・市場相場に着目した評価方法(マーケット・アプローチ)',
  salePriceAns:
    'M&Aの売却価格は採用した企業価値算定方法をベースとして、売却企業と買収希望企業間の交渉を経て決定されます。',
  awarenessAns:
    'M&Aを成功させるために必要なことはいくつかありますが、私たちは「準備」が重要だと考えています。交渉の過程で、新たな論点が生じないよう、準備の段階での論点把握が重要です。',
  serviceProvideAns:
    'M&Aを検討する会社(売却、譲受ともに)、または売却を希望する会社を支援する士業・専門家が、オンラインで候補先マッチングから交渉・契約実務まで行うことができるサービスです。従来M&Aの専門会社がいないとできなかったようなサービスを安価に、気軽に提供いたします。',
  usageAns: 'M&Aナビの利用料は売り手は完全無料、買い手は成約時に費用が発生します。',
  registerAns:
    'M&Aにより会社を売却しようと考えているのであれば、早めの登録をお勧めします。登録してすぐにM&Aが成約するわけではありません。M&Aナビへご登録いただければ、貴社の株価を無料算定させていただきます。',
  securityAns: '当社では登録していただいた情報の管理に細心の注意を払っておりますので、ご安心してご相談ください。',
  negotiateBuyerAns: 'M&Aナビ上で交渉できるよう、オンラインチャット機能を備えています。',
  userAns:
    '（1）売却希望企業 （2）買収希望企業 （3）公認会計士、税理士、M&A仲介会社等の専門家（4）個人の方が登録可能です。',
  registrationAns:
    '株式会社以外にも、個人、有限会社、医療法人、合同会社であっても登録が可能です。ただし、その場合、株式売却ができませんので事業売却のみになります。',
  restrictionAns:
    '基本的には登録制限はございません。事業規模の小さな会社から大きな会社までご登録いただけます。ただし、会社の株主、取引先等のステークホルダーに反社会的勢力の関係者の方がいらっしゃる場合等の一定の場合には登録を制限させていただいております。',
  recommendedAns: '推奨ブラウザは下記のとおりです。\nGoogle Chrome：最新版\nSafari：最新版',
  forgotPasswordAns1: 'パスワードがわからなくなってしまった場合には、パスワードの再発行を行いますので、',
  forgotPasswordAns2: 'のページより再発行依頼をお願いします。',
  consultationAns1: '当社（運営会社：株式会社M&Aナビ）に直接ご依頼いただくことも可能です。',
  consultationAns2: 'よりご連絡ください。',
  contact: 'その他ご不明な点などありましたら、お気軽にお問合せください。',
  contactLink: 'お問い合わせはこちら',
  here: 'こちら',
}
