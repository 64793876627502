import { ObjectType } from './object'

export interface Contact {
  id: number
  companyName: string
  name: string
  email: string
  tel: string
  replyMethod: number | null
  contactType: number
  text: string
  createdAt: string
}

export enum ProjectInquiryOptions {
  Company = 1,
  Person = 2,
  Inquiries = 9,
}

export const SellerInquiryOptions: ObjectType = {
  1: '事業の売却について相談したい',
  2: '株価算定を依頼したい',
  3: 'M&Aについて知りたい ',
  9: 'その他',
}

export const AmountOfSalesOptions: ObjectType = {
  1: '1,000万円未満',
  2: '1,000~5,000万円',
  3: '5,000万円~1億円',
  4: '1億円以上',
}

export const SaleReasonOptions: ObjectType = {
  1: '後継者不在',
  2: '先行き不安',
  3: '事業の選択と集中',
  4: '企業の更なる成長',
  9: 'その他',
}
