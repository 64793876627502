import { LevelListObject } from 'types/levelFilter'

export interface LinkDataColumn {
  id: number
  columnNumber: number
  columnName: string
  dataSourceGroupId: number
  sampleData: string
  linkDataGroupId?: number
  linkDataGroupName?: string
  dataType: number
}

export interface LinkDataGroup {
  id: number
  bankId: number
  name: string
  sortKey: number
}

export enum DisclosureRange {
  MainStoreOnly = 0,
}

export enum DataSourceKind {
  File = 0,
}

export interface LinkDataSource {
  id: number
  name: string
  explanation: string
  lastImportedAt: Date
  dataSourceKind: DataSourceKind
  disclosureRange: DisclosureRange
  editable: boolean
  linkDataColumns: LinkDataColumn[]
}

export type LinkDataSourceSelected = {
  levelList: LevelListObject | null
  value: string | number
  error?: boolean | null
  formatError?: number
  dataType?: number
  condition?: number
}

export type GroupDataSetting = {
  name?: string
  contents: {
    columnName: string
    sampleData: string
    dataType: number
  }[]
}
