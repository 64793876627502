import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { User, UserRole } from 'types/user'
import { ProjectMatching } from 'types/project'
import { OfferStatus } from 'types/offer'

type FavoriteParams = {
  page: number
  size: number
}
type FavoritesResult = {
  userFavorites?: ProjectMatching[]
  userFavoriteIds?: number[]
  handleClickFavorite: (projectId: number) => void
  count?: number
  fetchUserFavorites: (params?: FavoriteParams) => Promise<void>
}

export function useFavorites(user: User | null, status?: OfferStatus): FavoritesResult {
  const [userFavorites, setUserFavorites] = useState<ProjectMatching[]>()
  const [count, setCount] = useState<number>()

  const fetchUserFavorites = useCallback(
    async (params?: FavoriteParams) => {
      const { data } = await axios.get('/api/favorites', { params: { ...params, status } })
      setUserFavorites(data.projects)
      setCount(data.count)
    },
    [status]
  )

  useEffect(() => {
    if (!user || (user.role !== UserRole.Buyer && user.role !== UserRole.Coordinator)) return
    fetchUserFavorites()
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [user])

  const handleAddFavorite = useCallback(
    async (params?: { userId: number; projectId: number }) => {
      if (!user) return
      await axios.post('/api/favorites', params)
      await fetchUserFavorites()
      /* eslint-disable react-hooks/exhaustive-deps */
    },
    [user]
  )

  const handleDeleteFavorite = useCallback(
    async (favoriteId: number) => {
      if (!user) return
      await axios.delete(`/api/favorites/${favoriteId}`)
      await fetchUserFavorites()
      setUserFavorites(userFavorites?.filter((item) => item.favoriteId !== favoriteId))
      /* eslint-disable react-hooks/exhaustive-deps */
    },
    [user, userFavorites]
  )

  const userFavoriteIds = userFavorites?.map((userFavorite) => userFavorite.id)

  const handleClickFavorite = (projectId: number) => {
    if (!userFavorites || !user) return

    const userFavoriteFindId = userFavorites.find((userFavorite) => userFavorite.id === projectId)?.favoriteId

    userFavoriteFindId
      ? handleDeleteFavorite(userFavoriteFindId)
      : handleAddFavorite({ userId: user.id, projectId: projectId })
  }

  return { userFavorites, userFavoriteIds, handleClickFavorite, count, fetchUserFavorites }
}
