import { UserRole } from 'types/user'

export const usersVoices = [
  {
    icon: '/images/matching/avatar_user_1.png',
    role: UserRole.Seller,
    userType: '売り手',
    userName: '飲食店',
    age: '40代',
    location: '東京',
    comment:
      '最初は買い手が見つかるのか不安でしたが、案件公開直後からすぐに交渉リクエストの連絡がきて、チャットでやりとりをしながら納得のいく決断をすることができました。スマホでも不便がないのでそのあたりが特に楽でしたね。',
  },
  {
    icon: '/images/matching/avatar_user_2.png',
    role: UserRole.Buyer,
    userType: '買い手',
    userName: 'オンラインフィットネス',
    age: '30代',
    location: '東京',
    comment:
      '新着通知メールで案件を知り、そのまま交渉リクエストをして代表の方とお話をしました。一連のやり取りはスムーズでしたし、なにより成約料がかからなかったので、早く安く買収をすませることができ、その分事業に集中することができました。',
  },
  {
    icon: '/images/matching/avatar_user_3.png',
    role: UserRole.Seller,
    userType: '売り手',
    userName: 'ホテル・宿泊',
    age: '50代',
    location: '東京',
    comment:
      'できるだけ早く売却を完了させたくて利用しました。幸い、複数の交渉リクエストをいただいたので、大変迷いました。事業の売却は初めてでしたので、契約書の雛形を活用したり、コラムを読んで勉強しながら交渉を進めました。',
  },
]
