export const projects = {
  title: {
    label: '案件名（35字以内）',
    example: '＜フィリピンのオフショア事業＞10名以上のエンジニアが在籍するWEB制作事業の事業譲渡',
  },
  feature: {
    label: '特徴（強み、PR）',
    example:
      '以下の流れでストーリーを書いてみてください。\n① 社名をふせて、事業を簡潔に紹介\n② 事業の強み\n③ 売却の背景\n④ 最後にひとこと',
  },
  staffComment: '',
  name: '案件名',
  capital: {
    label: '資本金（円）',
  },
  scheme: {
    label: '売却対象',
    1: '株式売却（100%）',
    2: '株式売却（一部売却）',
    3: '事業売却',
    8: 'その他',
  },
  employeeNum: {
    labelPublic: '従業員数',
    label: '従業員数（他のユーザーには大まかな数が表示されます）',
    0: '社員なし',
    1: '5人以下',
    6: '10人以下',
    11: '20人以下',
    21: '30人以下',
    31: '50人以下',
    51: '100人以下',
    101: '200人以下',
    201: '201人以上',
  },
  publishedAt: '公開日時',
  establishedAt: {
    label: '設立年月',
    year: '年',
    month: '月',
  },
  when: {
    label: '希望売却時期',
    title: '売却希望時期',
    1: 'すぐにでも',
    2: '半年以内',
    3: '1年以内',
    4: 'お相手次第',
    5: '時期未定',
  },
  howMuch: {
    label: '希望売却金額',
    suffix: '万円',
  },
  takeOverPeriod: {
    label: '代表者の処遇（引き継ぎ期間）',
    1: '継続希望',
    2: '三ヶ月以内',
    3: '半年以内',
    4: '一年以内',
    5: '一年以上',
    6: '柔軟に対応',
    7: '応相談',
  },
  buyer: {
    label: '希望売却候補先',
    edit: '希望売却候補先（複数選択可能）',
    1: '法人',
    2: '個人',
    3: '問わない',
  },
  reason: {
    label: '売却理由',
    other: '売却理由（その他）',
    edit: '売却理由（複数選択可能）',
  },
  saleReasonText: {
    label: 'その他売却理由',
    other: '希望条件（その他）',
    edit: 'その他を選択した方は、売却理由をご記入ください',
  },
  ownedFacilityTxt: {
    label: '保有設備・車両など',
  },
  termsOfSaleText: {
    label: 'その他の条件',
  },
  businessTxt: {
    label: '事業内容',
  },
  shareholderTxt: {
    label: '主要株主',
  },
  supplierTxt: {
    label: '主要取引先',
  },
  vendorTxt: {
    label: '主要仕入先',
  },
  license: {
    label: '許認可',
    edit: '許認可（取得している許認可を入力してください）',
  },
  affiliateFlg: {
    label: '関連会社',
    options: {
      0: 'なし',
      1: 'あり',
    },
  },
  financeMemo: {
    label: '財務情報補足',
    desc: '※ より具体的に記載できることがあれば記入してください',
  },
  industryDetail: {
    label: '業種（小項目）',
  },
  industry: {
    label: '業種（大項目）',
  },
  bs: {
    header: 'B/S（賃借対照表）',
  },
  pls: {
    header: 'P/L（損益計算書）',
  },
  projectsSaleReasons: {
    label: '売却理由',
  },
  projectsTermsOfSales: {
    label: 'その他の条件',
    yes: 'はい',
    no: 'いいえ',
  },
  status: {
    '0': '下書き',
    '1': '審査中',
    '2': '修正依頼中',
    '3': '公開中',
    '4': '交渉リクエスト制限中',
    '5': '成約済み',
    '9': '公開停止中',
  },
  noteNDA: 'NDA締結者のみ詳細閲覧可',
  verified: '本人確認済',
}
