type Contrivance = {
  imgUrl: string
  altImg: string
  title: string
  description: string
  imgPosition: 'top' | 'center'
  width: string
  height: string
}

export const contrivance: Contrivance[] = [
  {
    imgUrl: '/images/matching/contrivance_1.png',
    altImg: 'contrivance_1',
    title: 'オンラインだからスピーディー！\n業界標準1/3の時間で成約',
    description: 'オンラインならではの柔軟さと、\n交渉プロセスの見える化で\nロスタイムをゼロに',
    imgPosition: 'center',
    width: '151',
    height: '151',
  },
  {
    imgUrl: '/images/matching/contrivance_2.png',
    altImg: 'contrivance_2',
    title: 'M&Aが初めての方でも\n簡単にはじめられる',
    description: '交渉の状況や次にやるべきことが\nすぐにわかる管理機能で、迷うことな\nく取引を進められます',
    imgPosition: 'top',
    width: '240',
    height: '143',
  },
  {
    imgUrl: '/images/matching/contrivance_3.png',
    altImg: 'contrivance_3',
    title: '魅力的な売り手に出会え\n直接やりとりができる',
    description:
      '様々な企業規模や\n業種の売り手・買い手が登録している\nから、多様な出会いがあり\n直接やりとりが可能です',
    imgPosition: 'center',
    width: '223',
    height: '72',
  },
]
