import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import { Box, styled, Typography, Grid, Chip, Stack } from '@mui/material'
import findLast from 'lodash/findLast'
import { ProjectMatching, ProjectStatus } from 'types/project'
import { UserRole } from 'types/user'
import { EMPTY_STRING } from 'utils/string'
import { newProjectDealCard as t } from 'public/locales/ja/components/pages/home/newProjectDealCard'
import { ImageFlux } from 'components/elements/ImageFlux'
import { toUnitHowMuch } from 'utils/formatNumber'
import { salesOptions } from 'utils/selectOptions'
import { amountSalesRange } from 'const/project'
import { ProjectImage } from 'components/elements/ProjectImage'

const TWO_WEEK = 2

type NewProjectDealCardProps = {
  project: Pick<
    ProjectMatching,
    | 'id'
    | 'industry'
    | 'title'
    | 'region'
    | 'howMuch'
    | 'amountOfSalesRange'
    | 'code'
    | 'company'
    | 'publishedAt'
    | 'status'
    | 'hasOgpImg'
    | 'presetImageUrl'
    | 'industryId'
  >
  role?: UserRole
  userFavoriteIds?: number[]
  onClickFavorite: (projectId: number) => void
}

export const NewProjectDealCard: React.FC<React.PropsWithChildren<NewProjectDealCardProps>> = ({
  project,
  userFavoriteIds,
  role,
  onClickFavorite,
}) => {
  const isShowTag = useMemo(
    () =>
      project.status === ProjectStatus.Published && moment().subtract(TWO_WEEK, 'week') <= moment(project.publishedAt),
    [project.status, project.publishedAt]
  )

  const handleFavorite = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, projectId: number) => {
      e.stopPropagation()
      onClickFavorite(projectId)
    },
    [onClickFavorite]
  )

  const handleOpenTag = useCallback((e: React.MouseEvent<HTMLDivElement>, link: string) => {
    e.stopPropagation()
    window.open(link, '_self')
  }, [])

  const getSalesRangeMin = (amountOfSalesRange: number): number => {
    if (!amountOfSalesRange || amountOfSalesRange < 0) return 0
    return findLast(salesOptions, (min) => min <= amountOfSalesRange) || 0
  }

  return (
    <Wrapper onClick={(e: React.MouseEvent<HTMLDivElement>) => handleOpenTag(e, `/projects/${project.code}`)}>
      <ImageWrapper alignItems={'center'} justifyContent={'center'}>
        {isShowTag && <StatusTag label={t.status} />}
        <ProjectImage project={project} imageStyle={{ width: '240', height: '160', heightMobile: '160' }} showIcon />
      </ImageWrapper>
      <Typography variant={'body2'} gutterBottom>
        {project.industry?.name || EMPTY_STRING}
      </Typography>
      <Typography minHeight={'52px'} fontWeight={'bold'} fontSize={20}>
        {project.title || EMPTY_STRING}
      </Typography>
      <ProjectInfoContainer container rowSpacing={0.5}>
        <Grid item xs={4.5}>
          <Typography>{t.area}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>{project.company?.prefectures?.regions?.name || EMPTY_STRING}</Typography>
        </Grid>
        <Grid item xs={4.5}>
          <Typography>{t.projectPrice}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>{toUnitHowMuch(project.howMuch) || EMPTY_STRING}</Typography>
        </Grid>
        <Grid item xs={4.5}>
          <Typography>{t.amountOfSales}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>
            {project.amountOfSalesRange === null
              ? ''
              : amountSalesRange[String(getSalesRangeMin(project.amountOfSalesRange))]}
          </Typography>
        </Grid>
      </ProjectInfoContainer>
      {role === UserRole.Buyer ||
        (role === undefined && (
          <LikeButton onClick={(e: React.MouseEvent<HTMLDivElement>) => handleFavorite(e, project.id)}>
            {userFavoriteIds?.includes(project.id) ? (
              <ImageFlux
                src={'/images/matching/heart.png'}
                loading={'lazy'}
                width={'48'}
                alt={`projects_${project.code}_ogp-image`}
              />
            ) : (
              <ImageFlux
                src={'/images/matching/heart_plus.png'}
                loading={'lazy'}
                width={'48'}
                height={'48'}
                alt={`projects_${project.code}_ogp-image`}
              />
            )}
          </LikeButton>
        ))}
    </Wrapper>
  )
}

const Wrapper = styled(Box)(
  ({ theme }) => `
  color: ${theme.tertiary.darkness3};
  position: relative;
  max-width: 332px;
  min-height: 405px;
  width: 100%;
  padding: ${theme.spacing(2)};
  border-radius: ${theme.spacing(0.75)};
  border: 2px solid ${theme.grey.lightness2};
  cursor: pointer;
`
)

const ImageWrapper = styled(Stack)(
  ({ theme }) => `
  max-width: 100%;
  width: 302px;
  height: 176px;
  position: relative;
  padding-bottom: ${theme.spacing(2)};
`
)

const StatusTag = styled(Chip)(
  ({ theme }) => `
  position: absolute;
  top: ${theme.spacing(-1)};
  left: ${theme.spacing(-1)};
  font-weight: 700;
  background: ${theme.primary.base};
  color: ${theme.palette.common.white};
  font-size: ${theme.typography.pxToRem(12)};
  padding: 0 ${theme.spacing(2)};
  z-index: 10;
`
)

const ProjectInfoContainer = styled(Grid)(
  ({ theme }) => `
  padding-top: ${theme.spacing(1)}
`
)

const LikeButton = styled(Box)(
  ({ theme }) => `
  position: absolute;
  width: 48px;
  height: 48px;
  cursor: pointer;
  right: ${theme.spacing(2)};
  bottom: ${theme.spacing(2)};
  img {
    max-width: 100%;
  }
`
)
