import React from 'react'
import Link from 'next/link'
import { Box, styled, Typography, Stack } from '@mui/material'
import { ImageFlux } from 'components/elements/ImageFlux'

type IndustrySearchProps = {
  imageUrl: string
  altImg: string
  searchUrl: string
  industryName: string
}

export const IndustrySearch: React.FC<React.PropsWithChildren<IndustrySearchProps>> = ({
  searchUrl,
  imageUrl,
  industryName,
  altImg,
}) => {
  return (
    <Link href={searchUrl} passHref legacyBehavior>
      <Wrapper>
        <IndustrySearchContainer alignItems={'center'} justifyContent={'center'}>
          <ImageContainer>
            <ImageFlux src={imageUrl} loading={'lazy'} width={'64'} height={'64'} alt={altImg} />
          </ImageContainer>
          <StyledTypography variant={'body1'} fontWeight={700}>
            {industryName}
          </StyledTypography>
        </IndustrySearchContainer>
      </Wrapper>
    </Link>
  )
}

const Wrapper = styled('a')(
  ({ theme }) => `
  background: ${theme.palette.common.white};
  box-shadow: -1px 1px 10px #0284fe33;
  border-radius: ${theme.spacing(0.75)};
  text-decoration: none;
`
)

const ImageContainer = styled(Box)(
  () => `
  max-width: 64px;
  img {
    max-width: 100%;
  }
`
)

const IndustrySearchContainer = styled(Stack)(
  ({ theme }) => `
  min-width: 240px;
  min-height: 120px;
  ${theme.breakpoints.down('sm')} {
    min-width: 164px;
    min-height: 140px;
  }
`
)

const StyledTypography = styled(Typography)(
  ({ theme }) => `
  text-align: center;
  color: ${theme.tertiary.darkness3};
  ${theme.breakpoints.down('sm')} {
    word-break: break-word;
    max-width: 114px;
  }
`
)
