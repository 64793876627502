export const publicHead = {
  ogTitle: 'M&Aナビ',
  description:
    '中小企業に特化したM&A・事業承継のプラットフォームです。売り手はもちろん、買い手・専門家も登録無料でご利用いただけます（買い手のみ成約時に費用が発生）。',
  website: 'website',
  locale: 'ja_JP',
  fbAppId: '144958799665868',
  twitter: {
    card: 'summary',
    site: '@ma_navigator',
  },
}
